import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import MaxRedirects from './MaxRedirects';
import Revealable from './Revealable';
import Locking from './Locking';
import DeadLink from './DeadLink';
import LoginMessage from './LoginMessage';
import Notifying from './Notifying';

const AdvancedSettings = props => {
    const user = useSelector(state => state.user.value);
    return (
        <Box sx={{m: 2, mt: 4, display: 'flex', flexDirection: 'column', rowGap: {xs: 2, sm: 1}}}>
            <MaxRedirects {...props} />
            <Revealable {...props} />
            {
                user?.isLogined &&
                <>
                    {/* TODO: finish it up in the future release \/ */}
                    {/* <Notifying {...props} />  */}
                    <Locking {...props} />
                </>
            }
            <DeadLink {...props} />
            {
                !user?.isLogined &&
                <LoginMessage />
            }
        </Box>
    );
}

export default AdvancedSettings;