import _ from 'lodash';
import { 
    useState, 
    useEffect 
} from 'react';
import { 
    useTheme,
    useMediaQuery,
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    tableCellClasses,
    styled,
    darken,
    Pagination,
} from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { withPather } from 'react-pather';
import Loader from '../../../Loader';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: darken(theme.palette.background.default, theme.palette.mode === 'dark' ? 0.125 : 0.06),
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const trendsPerPage = 50;

const Trends = ({ pather }) => {

    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const matches = useMediaQuery(theme.breakpoints.only('xs'));

    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);

    // Pagination
    const [offset, setOffset] = useState(0);
    const [totalPages, setTotalPages] = useState(1);

    const fetchTrends = () => {
        setLoading(true);
        axios.get(pather.back.Settings.trends, 
            { 
                params: { 
                    perPage: trendsPerPage,
                    offset,
                } 
            }
        )
        .then(response => {
            const data = response.data;
            console.log(data);
            setRows(data?.rows);
            setTotalPages(data?.total_pages);
        })
        .catch(error => {
            console.log(error);
            const status = error?.response?.data?.status;
            status && enqueueSnackbar(status, { variant: 'error' });
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchTrends();
    }, []);

    useEffect(() => {
        fetchTrends();
    }, [offset]);

    const handleOffsetChange = (event, value) => setOffset(value - 1);

    return (
        <>
            {
                loading ? 
                <Loader/> :
                <Box>
                    {   
                        !_.isEmpty(rows) ?
                        <TableContainer component={Paper}>
                            <Table 
                                sx={{ minWidth: 650 }} 
                                aria-label="simple table"
                            >
                                <TableHead sx={{
                                    backgroundColor: darken(
                                        theme.palette.background.default, theme.palette.mode === 'dark' ? 0.125 : 0.06
                                    )
                                }}>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">Position</StyledTableCell>
                                        <StyledTableCell>Domain</StyledTableCell>
                                        <StyledTableCell align="center">Active</StyledTableCell>
                                        <StyledTableCell align="center">Forgotten</StyledTableCell>
                                        <StyledTableCell align="center">Deleted</StyledTableCell>
                                        <StyledTableCell align="center">Inactive</StyledTableCell>
                                        <StyledTableCell align="center">Total</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                {rows.map(row => (
                                    <StyledTableRow
                                        key={row.id}
                                        sx={offset === 0 && { 
                                            '&:nth-child(-n+3) td, &:nth-child(-n+3) th': {
                                                backgroundColor: theme.palette.mode === 'dark' ? '#639A67' : '#A6CF98'
                                            },
                                            '&:nth-child(n+4):nth-child(-n+10) td, &:nth-child(n+4):nth-child(-n+10) th': {
                                                backgroundColor: theme.palette.mode === 'dark' ? '#E7B10A' : '#FFD23F',
                                            },
                                            '&:nth-child(10) td, &:nth-child(10) th': {
                                                borderBottom: '2px solid red',
                                            },
                                            '&:last-child td, &:last-child th': { border: 0 } 
                                        }}
                                    >
                                        <StyledTableCell align="center" component="th" scope="row">
                                            {row.id}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {row.active}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {row.forgotten}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {row.deleted}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {row.inactive}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {row.active + row.forgotten + row.deleted + row.inactive}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer> :
                        <Box sx={{height: '50vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography variant="h6" component="div">
                                No data
                            </Typography>
                        </Box>
                    }
                    {   
                        totalPages > 1 && 
                        <Box sx={{width: '100%', mt: 3, display: 'flex', justifyContent: 'center'}}>
                            <Pagination
                                count={totalPages} 
                                page={offset + 1} 
                                variant="outlined" 
                                color="primary" 
                                size={matches ? "medium" : "large"}
                                onChange={handleOffsetChange}
                            />
                        </Box>
                    }
                </Box>
            }
        </>
    )
}

export default withPather()(Trends);