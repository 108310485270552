import { Routes, Route } from "react-router-dom"; 
import * as Components from './Components';
import { withPather } from 'react-pather';
import PrivateRoute from "./PrivateRoute";

const SystemRoutes = ({ pather }) => {
    return (
        <Routes>
            {/* Public Routest */}
            <Route path="/" element={<Components.Home />} />
            <Route path={pather.front.shorten} element={<Components.Shorten />} />
            <Route path={pather.front.main} element={<Components.Redirect />} />
            <Route path={pather.front.login} element={<Components.Login />} />
            <Route path={pather.front.signup} element={<Components.SignUp />} />
            <Route path={pather.front.confirmAccount} element={<Components.ConfirmAccount />} />

            {/* Private Routest */}
            <Route element={<PrivateRoute />}>
                <Route path={pather.front.dashboard} element={<Components.Dashboard />} />
                <Route path={pather.front.view} element={<Components.ConversionView />} />
                <Route path={pather.front.settings} element={<Components.Settings />} />
            </Route>

            {/* 404 */}
            <Route path="*" element={<Components.PageNotFound />} />
        </Routes>
    );
}

export default withPather()(SystemRoutes);