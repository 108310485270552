import { createSlice } from '@reduxjs/toolkit'

export const ratingSlice = createSlice({
    name: 'rating',
    initialState: {
      value: 5
    },
    reducers: {
      setRating: (state, action) => {
        state.value = action.payload;
      },
      resetRating: (state, action) => {
        state.value = 5;
      },
    }
})

export const { setRating, resetRating } = ratingSlice.actions;

export default ratingSlice.reducer;