import { useState } from 'react';
import Box from '@mui/material/Box';
import PasswordField from '../general/PasswordField';
import Button from '@mui/material/Button';

const PasscodeCheck = ({ passcode, setPasscode, proceed }) => {
    const [showPasscode, setShowPasscode] = useState(false);
    const handleClickShowPasscode = () => setShowPasscode(show => !show);
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, mt: {xs: 5, sm: 15}, mb: {xs: 5, sm: 20}}}>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',  flexWrap: 'wrap', flexDirection: {xs: 'column', sm: 'row'}, gap: 3}}>
                <Box sx={{fontSize: {md: 22, xs: 16}}}>
                    Enter passcode: 
                </Box>
                <Box>
                    <PasswordField
                        label=''
                        value={passcode} 
                        handleChange={e => setPasscode(e.target.value)} 
                        show={showPasscode} 
                        handleShow={handleClickShowPasscode}
                        style={{width: {xs: '100%', sm: '40vw', md: '30vw', lg: '20vw'}}}
                    />
                </Box>
            </Box>
            <Box>
                <Button 
                    variant="contained" 
                    sx={{p: 7, pt: 1, pb: 1}}
                    onClick={proceed}
                >
                    Continue
                </Button>
            </Box>
        </Box>
    );
}

export default PasscodeCheck;