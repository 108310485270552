import { Box } from "@mui/material";
import ScrollToTop from "react-scroll-to-top";
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { useTheme } from '@emotion/react';

const ScrollToTopButton = () => {
    const theme = useTheme();
    return (
        <ScrollToTop 
            smooth 
            style={{
                backgroundColor: theme.palette.background.default,
                border: `1px solid ${theme.palette.text.main}`,
                borderRadius: '10px',
                boxShadow: 'none'
            }}
            component={
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <KeyboardArrowUpRoundedIcon sx={{color: theme.palette.text.main}}/>
                </Box>
            }
        />
    );
}

export default ScrollToTopButton;