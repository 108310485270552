import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Introduction from './Introduction';
import ConversionExample from './ConversionExample';
import Features from './Features';
import StartNow from './StartNow';

const Home = () => {
    return (
        <Container>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Introduction/>
                <ConversionExample/>
                <Features/>
                <StartNow/>
            </Box>
        </Container>
    );
}

export default Home;