import { Container } from '@mui/material';
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import TopBar from './TopBar';
import ConversionListViewer from '../general/ConversionListViewer';
import { 
    setQuery, 
    setPerPage, 
    setOffset, 
    setFreshness,
    setMode, 
    setDateSort, 
    setStartDate, 
    setEndDate 
} from '../../../slices/dashboardSettingsSlice';

const Dashboard = () => {

    const dispatch = useDispatch();

    const dashboardSettings = useSelector(state => state.dashboardSettings.value);

    const _setQuery = val => dispatch(setQuery(val));
    const _setPerPage = val => dispatch(setPerPage(val));
    const _setOffset = val => dispatch(setOffset(val));
    const _setFreshness = val => dispatch(setFreshness(val));
    const _setMode = val => dispatch(setMode(val));
    const _setDateSort = val => dispatch(setDateSort(val));
    const _setStartDate = val => dispatch(setStartDate(val));
    const _setEndDate = val => dispatch(setEndDate(val));

    return (
        <Container sx={{height: '100%', mb: 10}}>
            <ConversionListViewer 
                settings={dashboardSettings}
                setMethods={{
                    setQuery: _setQuery,
                    setPerPage: _setPerPage,
                    setOffset: _setOffset,
                    setFreshness: _setFreshness,
                    setMode: _setMode,
                    setDateSort: _setDateSort,
                    setStartDate: _setStartDate,
                    setEndDate: _setEndDate,
                }}
                settingsTopBar={
                    <TopBar />
                }
            />
        </Container>
    );
}

export default Dashboard;