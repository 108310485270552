import { useState } from 'react';
import {
    Box, 
    TextField, 
    Button,
    Typography
} from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { withPather } from 'react-pather';
import Loader from '../../../Loader';

const Contact = ({ pather }) => {

    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');  

    const [errorTitle, setErrorTitle] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const isInputValid = () => {
        // Reset values
        setErrorTitle('');
        setErrorMsg('');
        
        // Validate
        if (!title.trim()) setErrorTitle('Title is not provided');
        else if (title.length < 4) setErrorTitle('Title length should be at least 4');
        else if (title.length > 100) setErrorTitle('Maximum title length is 100');
        else if(!message.trim()) setErrorMsg('Message is not provided');
        else if(message.length < 5) setErrorMsg('Message length should be at least 5');
        else if(message.length > 1500) setErrorMsg('Maximum message length is 1500');
        else return true;
    }

    const submit = () => {
        if(!isInputValid()) return;
        setLoading(true);
        axios.post(
            pather.back.Settings.contact, 
            { 
                title,
                body: message,
            }
        )
        .then(response => {
            enqueueSnackbar(response.data.status, { variant: 'success' });
            reset();
        })
        .catch(error => {
            console.log(error);
            const status = error?.response?.data?.status;
            if(status) enqueueSnackbar(status, { variant: 'error' });
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const reset = () => {
        setTitle('');
        setMessage('');
    }

    return (
        <>
            {
                loading ? 
                <Loader/> :
                <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                    <Box>
                        <Typography variant="h6" component="div">
                            Title:
                        </Typography>
                        <TextField 
                            id="contact-title" 
                            variant="filled" 
                            fullWidth
                            placeholder='E.g. feature proposal'
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            error={!!errorTitle}
                            helperText={errorTitle}
                        />
                    </Box>
                    <Box>
                        <Typography variant="h6" component="div">
                            Description:
                        </Typography>
                        <TextField 
                            id="contact-desciption" 
                            variant="filled" 
                            multiline
                            minRows={7}
                            maxRows={12}
                            placeholder='Your message'
                            fullWidth
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            error={!!errorMsg}
                            helperText={errorMsg}
                        />
                    </Box>
                    <Box>
                        <Typography fontSize='13px' component="div">
                            Reply will be sent to the email registered in your account. Make sure your email is confirmed.
                        </Typography>
                    </Box>
                    <Box sx={{marginLeft: 'auto'}}>
                        <Button 
                            variant="contained" 
                            size="large"
                            onClick={submit}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            }
        </>
    );
};

export default withPather()(Contact);