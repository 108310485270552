import { useState } from 'react';
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import {  
    Box, 
    TextField, 
    Button
} from '@mui/material';
import { CancelRounded } from '@mui/icons-material';
import { withPather } from 'react-pather';
import useToken from '../../../auth/useToken';
import Loader from '../../../Loader';
import { PasswordFieldExternalLabel, Confirmation } from '../../../general';
import { validateEmail } from '../../../../utils';
import { setUser } from '../../../../slices/userSlice';
import { resetDashboardSettings } from '../../../../slices/dashboardSettingsSlice';
import { resetSettingsPage } from '../../../../slices/settingsPageSlice';
import { resetForgottenSettings } from '../../../../slices/forgottenSettingsSlice';

const Field = ({ label, value, type, onChange }) => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
            <Box>
                <span>
                    {label}
                </span>
            </Box>
            <TextField 
                value={value} 
                type={type} 
                variant="outlined" 
                sx={{width: '100%'}} 
                onChange={onChange}
            />
        </Box>
    );
}

const General = ({ pather }) => {

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { removeToken } = useToken();
    
    const user = useSelector(state => state.user.value);

    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState(user?.username);
    const [email, setEmail] = useState(user?.email);
    const [password, setPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [openConfirmDeactivate, setOpenConfirmDeactivate] = useState(false);

    // Password visibility
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(show => !show);
    const handleClickShowNewPassword = () => setShowNewPassword(show => !show);
    //

    const onUsernameChange = e => {
        const value = e.target.value;
        setUsername(value);
    }

    const onEmailChange = e => {
        const value = e.target.value;
        setEmail(value);
    }

    const validateFields = () => {
        if(!validateEmail(email)){
            enqueueSnackbar('Invalid email!', { variant: 'error' });
            return false;
        }   
        return true;
    }

    const update = () => {
        setLoading(true);
        if(!validateFields()) {
            setLoading(false);
            return;
        }
        axios.put(
            pather.back.Settings.user, 
            {
                username,
                email,
                password,
                newPassword,
            },    
        )
        .then(response => {
            const data = response.data;
            dispatch(setUser({
                isLogined: true,
                email: data.email,
                username: data.username,
                isConfirmed: data.is_confirmed,
            }));
            enqueueSnackbar(data.status, { variant: 'success' });
            setPassword(null);
            setNewPassword(null);
        })
        .catch(response => {
            const data = response?.response?.data;
            enqueueSnackbar(data?.status ? data?.status : 'Invalid input!', { variant: 'error' });
            console.log(response);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const deactivate = () => {
        setLoading(true);
        axios.post(pather.back.Settings.User.deactivate)
        .then(response => {
            const data = response.data;
            enqueueSnackbar(data.status, { variant: 'success' });
            // Logout user
            axios.post(pather.back.Auth.logout)
            .then(() => {
                removeToken();
                dispatch(setUser({}));
                dispatch(resetDashboardSettings());
                dispatch(resetForgottenSettings());
                dispatch(resetSettingsPage());
                navigate('/');
            })
            .catch(response => {
                const data = response?.response?.data;
                data?.status && enqueueSnackbar(data.status, { variant: 'error' });
                console.log(response);
            })
        })
        .catch(response => {
            const data = response?.response?.data;
            data?.status && enqueueSnackbar(data.status, { variant: 'error' });
            console.log(response);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const width = {xs: '100%', sm: '70%', md: '40vw', lg: '500px'}

    return (
        <>
            {
                loading ? 
                <Loader/> :
                <Box sx={{display: 'flex', flexDirection: 'column', gap: 4}}>
                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 2}}>
                        <Box sx={{width: width, display: 'flex', flexDirection: 'column', gap: 2}}>
                            <Field 
                                label='Username: ' 
                                value={username} 
                                type="text"
                                onChange={onUsernameChange}
                            />
                            <Field 
                                label='Email: ' 
                                value={email} 
                                type="text"
                                onChange={onEmailChange}
                            />
                            <PasswordFieldExternalLabel 
                                externalLabel='Password: ' 
                                value={password}
                                handleChange={e => setPassword(e.target.value)} 
                                show={showPassword}
                                handleShow={handleClickShowPassword}
                            />
                            <PasswordFieldExternalLabel
                                externalLabel='New password: '
                                value={newPassword}
                                handleChange={e => setNewPassword(e.target.value)} 
                                show={showNewPassword}
                                handleShow={handleClickShowNewPassword}
                            />
                        </Box>
                        <Box sx={{width: width, display: 'flex', justifyContent: 'flex-end'}}>
                            <Button 
                                variant="contained" 
                                size="large"
                                onClick={update}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}> 
                        <Button 
                            variant="outlined" 
                            color="error" 
                            startIcon={<CancelRounded />}
                            sx={{
                                width: width,
                                pt: 1.5,
                                pb: 1.5,
                            }}
                            onClick={() => setOpenConfirmDeactivate(true)}
                        >
                            Deactivate account
                        </Button>
                    </Box>
                    <Confirmation 
                        open={openConfirmDeactivate}
                        setOpen={setOpenConfirmDeactivate}
                        title="Account deactivation"
                        description={
                            `Are you sure you want to deactivate your account? This action can not be reverted.`
                        }
                        success={deactivate}
                    />
                </Box>
            }
        </>
    );
}

export default withPather()(General);