import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider as ReduxProvider } from 'react-redux';
import axios from 'axios';
import { UUT, UUTLC, MAINTENANCE_MODE  } from './config';
import MaintenanceWindow from './components/maintenance'; 

const root = ReactDOM.createRoot(document.getElementById('root'));

// Axios Settings
axios.defaults.headers.common[UUT] = localStorage.getItem(UUTLC);
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-type'] = 'application/json';
//

root.render(
  <React.StrictMode>
    {
      MAINTENANCE_MODE ?
      <MaintenanceWindow /> :
      <ReduxProvider store={store}>
        <App/>
      </ReduxProvider>
    }
  </React.StrictMode>  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
