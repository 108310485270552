import { 
    useTheme,
    Box,
    TextField,
    Typography,
    Tooltip,
} from '@mui/material';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

const MaxRedirects = ({ advanced, setAdvancedSafe }) => {
    const theme = useTheme();
    return (
        <Box sx={{display: 'flex', alignItems: {xs: 'flex-start', sm: 'flex-end'}, flexDirection: {xs: 'column', sm: 'row'}, gap: {xs: 2}}}>
            <Box>
                <Typography valiant="h1" component="div">
                    Max number of redirects: 
                </Typography>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'flex-end', gap: {xs: 1}}}>
                <Box>
                    <TextField
                        id="outlined-number-redirects"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                        value={advanced.maxRedirects}
                        onChange={e => {
                            const val = e.target.value;
                            setAdvancedSafe({
                                maxRedirects: !!val ? val : null
                            });
                        }}
                    />
                </Box>
                <Box>
                    <Tooltip title='By default "infinity"' placement="right">
                        <HelpRoundedIcon fontSize='small' sx={{color: theme.palette.mode === 'light' && '#757575'}} />
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
}

export default MaxRedirects;