
const baseTheme = {
  typography: {
    fontFamily: [
      'Comfortaa',
    ].join(' ')
  },
}

const basePalette = {
  primary: {
    main: '#4791DB',
  },
  secondary: {
    main: '#f50057',
  },
  error: {
    main: '#d32f2f',
  },
}


const darkTheme = {
  ...baseTheme,
  palette: {
    mode: "dark",
    ...basePalette,
    background: {
      default: "#393E46",
      paper: "#222831",
    },
    text:{
      main: '#FFFFFF'
    },
    squareBoxShadow: '0px 5px 12px #272a2b',
  },
  link: {
    '&:link': { color: '#9E9EFF', textDecorationColor: '#9E9EFF' },
    '&:visited': { color: '#D0ADF0', textDecorationColor: '#D0ADF0' },
  },
}

const lightTheme = {
  ...baseTheme,
  palette: {
    mode: "light",
    ...basePalette,
    background: {
      default: "#FFFFFF"
    },
    text:{
      main: '#121212'
    },
    squareBoxShadow: '0px 5px 12px #D9DBDF',
  },
  link: {
    '&:link': { color: '#0000EE', textDecorationColor: '#0000EE' },
    '&:visited': { color: '#551A8B', textDecorationColor: '#551A8B' },
  },
}


const themes = {
  'light': lightTheme,
  'dark': darkTheme,
}


export { 
  baseTheme,
  basePalette,
  darkTheme, 
  lightTheme,
};

export default themes;