
const __DEV__ = process.env.NODE_ENV === 'development';

const BACKEND_URL = __DEV__ ? 'http://127.0.0.1:5000' : process.env.REACT_APP_BACKEND_URL;

const BACKEND_API_ENDPOINT = '/api/v1';

const UUT = 'User-Unique-Token';
const UUTLC = 'uut';  // UUT Local Storage

const JWTLC = 'token';

// Maintenance
const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE;

export {
    BACKEND_URL,
    BACKEND_API_ENDPOINT,
    __DEV__,
    UUT,
    UUTLC,
    JWTLC,

    // Maintenance
    MAINTENANCE_MODE,
}
