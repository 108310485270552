import { Box, useTheme, Chip } from '@mui/material';

const InfoChips = ({ conversion, size = 'medium' }) => {
    const theme = useTheme();
    return (
        <Box sx={{display: 'flex', gap: 1, flexWrap: 'wrap'}}>
            {
                conversion?.isAutoCode &&
                <Chip 
                    label="AutoCode" 
                    size={size} 
                    sx={{backgroundColor: theme.palette.mode === 'light' ? '#451952' : '#662549', color: 'white'}}
                />
            }
            {
                conversion?.isSmartCode &&
                <Chip label="SmartCode" size={size} color="secondary" sx={{color: 'white'}}/>
            }
            {
                conversion?.isCustomCode &&
                <Chip label="CustomCode" size={size} color="warning" sx={{color: 'white'}}/>
            }
            {   
                conversion?.revealable ?
                <Chip label="Revealable" size={size} color="info" sx={{color: 'white'}}/> :
                <Chip 
                    label="Hidden" 
                    size={size}
                    sx={{backgroundColor: theme.palette.mode === 'light' ? '#144272' : '#2C74B3', color: 'white'}}
                />
            }
            {
                conversion?.passcode &&
                <Chip 
                    label="Locked" 
                    size={size} 
                    sx={{backgroundColor: theme.palette.mode === 'light' ? '#36846B' : '#4BB39A', color: 'white'}}
                /> 
            }
            {
                conversion?.isDead &&
                <Chip 
                    label="isDead" 
                    size={size} 
                    sx={{backgroundColor: theme.palette.mode === 'light' ? '#7D0A0A' : '#BF3131', color: 'white'}}
                />
            }
        </Box>
    );
}

export default InfoChips;