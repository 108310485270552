import { createSlice } from '@reduxjs/toolkit'
import { JWTLC } from '../config';
import axios from 'axios';

const getJWTFromLocalStorage = () => {
    const jwt = localStorage.getItem(JWTLC);
    return jwt && jwt;
}

export const jwtSlice = createSlice({
    name: 'jwt',
    initialState: {
      value: getJWTFromLocalStorage()
    },
    reducers: {
      setJWT: (state, action) => {
        state.value = action.payload;
        localStorage.setItem(JWTLC, state.value);
        axios.defaults.headers.common['Authorization'] = `Bearer ${state.value}`;
      },
      unsetJWT: (state) => {
        state.value = null;
        localStorage.removeItem(JWTLC);
        axios.defaults.headers.common['Authorization'] = null;
      },
    }
})

export const { setJWT, unsetJWT } = jwtSlice.actions

export default jwtSlice.reducer;