import TextField from '@mui/material/TextField';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

const PasswordField = ({ label, value, handleChange, show, handleShow, error, helperText, style, variant = "outlined" }) => {
    const handleMouseUpDownPassword = event => event.preventDefault();
    return (
        <TextField 
            label={label} 
            sx={{
                width: '100%',
                ...style
            }}
            variant={variant}
            value={value} 
            onChange={handleChange} 
            type={show ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShow}
                            onMouseUp={handleMouseUpDownPassword}
                            onMouseDown={handleMouseUpDownPassword}
                            edge="end"
                        >
                            {show ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
            error={error}
            helperText={helperText}
        />
    );
}


const PasswordFieldExternalLabel = (props) => {
    const { externalLabel, ...otherProps } = props;
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
            <Box>
                <span>
                    {externalLabel}
                </span>
            </Box>
            <PasswordField {...otherProps} />
        </Box>
    );
}

export default PasswordField;

export {
    PasswordFieldExternalLabel
}