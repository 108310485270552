import { 
    Box, 
    IconButton
} from '@mui/material';
import { UpdateRounded } from '@mui/icons-material';
import PerPageSwitcher from '../../general/ConversionListViewer/ConversionListSettings/PerPageSwitcher';

const RedirectsHistoryTopBar = props => {
    const {
        redirectsNumOfResults,
        fetchRedirectsWithOffsetReset,
        redirectsPerPage,
        handleRedirectsPerPageChange
    } = props;
    return (
        <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>    
            <Box>
                <h2>
                    Redirect History ({ redirectsNumOfResults })
                </h2>
            </Box>
            <Box>
                <IconButton onClick={fetchRedirectsWithOffsetReset}>
                    <UpdateRounded/>
                </IconButton>
            </Box>
            <Box sx={{marginLeft: 'auto'}}>
                <PerPageSwitcher
                    perPage={redirectsPerPage}
                    handlePerPageChange={handleRedirectsPerPageChange}
                    variant="standard"
                    inputText=""
                />
            </Box>
        </Box>
    );
}

export default RedirectsHistoryTopBar;