import { createSlice } from '@reduxjs/toolkit'
import { UUTLC } from '../config';


const getUUTFromLocalStorage = () => {
  return localStorage.getItem(UUTLC);
}

export const tokenSlice = createSlice({
    name: 'token',
    initialState: {
      value: getUUTFromLocalStorage()
    },
    reducers: {
      setToken: (state, action) => {
        state.value = action.payload;
        localStorage.setItem(UUTLC, state.value);
      },
      unsetToken: (state) => {
        state.value = null;
        localStorage.removeItem(UUTLC);
      },
    }
})

export const { setToken, unsetToken } = tokenSlice.actions

export default tokenSlice.reducer;