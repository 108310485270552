import { 
    Box, 
    Typography 
} from '@mui/material';
import { InfoChips } from '../general';

const Details = ({ conversion }) => {
    return (
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Box>
                <Box sx={{ mb: 2 }}>
                    {   
                        conversion?.maxRedirects &&
                        <Typography sx={{ mt: 1 }} variant="h6" component="div">
                            Limit of successful redirects: {conversion.maxRedirects}
                        </Typography>
                    }
                </Box>
                <InfoChips conversion={conversion} />
            </Box>
        </Box>
    );
}

export default Details;