import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const ShortenBtn = ({ shorten }) => {
    return (
        <Box sx={{display: 'flex', justifyContent: {md: 'flex-end', xs: 'center'}, mt: '4vw', mr: {md: 3}}}>
            <Button 
                variant="contained"
                onClick={shorten}
                sx={{p: 2, borderRadius: '30px', width: {md: '12vw',  sm: '50%', xs: '100%'}, fontWeight: 'bold', boxShadow: 5 }}
            >
                Shorten
            </Button>
        </Box>
    );
}

export default ShortenBtn;