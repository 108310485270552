import Box from '@mui/material/Box';
import { useTheme } from '@emotion/react';

const Introduction = () => {
    const theme = useTheme();
    return (
        <>
            <Box 
                sx={{ 
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
               
                minHeight='50vh'
            >
                <Box 
                    className="noselect"
                    sx={{ 
                        fontFamily: 'Emblema One', 
                        color: theme.palette.text.main, 
                        fontSize: '2.5rem',
                    }}
                >
                    <h1>Shortl</h1>
                </Box>
                <Box>
                    <h2>
                        Advanced URL shortener with numerous of exceptional features <br/>
                    </h2>
                </Box>
            </Box>
        </>
    );
}

export default Introduction;