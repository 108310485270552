import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const AccountConfirmation = ({ confirmationLoading, sendConfirmationLink }) => {
    return (
        <Box sx={{width: {xs: '92%', sm: '97%'}}}>
            <Box className="attention" sx={{display: 'flex', 
                width: '100%', alignItems: 'center', justifyContent: 'center',
                fontSize: '1.1rem', p: 2,
                backgroundColor: '#E84545', color: 'white', borderRadius: '20px',
                flexDirection: {xs: 'column', sm: 'row'}, gap: {xs: 2, sm: 0}
            }}>
                <Box>
                    Confirm your account clicking on a link in email
                </Box>
                {
                    confirmationLoading ?
                    <Box sx={{marginLeft: {sm: 'auto'}}}>
                        <CircularProgress size={30}/>
                    </Box> :
                    <Button 
                        variant='contained' 
                        color="secondary"
                        sx={{backgroundColor: '#942c2c', marginLeft: {sm: 'auto'}, width: {xs: '100%', sm: 'auto'}}}
                        onClick={sendConfirmationLink}
                    >
                        Send link
                    </Button>
                }
            </Box>
        </Box>
    );
}

export default AccountConfirmation;