import {
    TextField,
    IconButton,
} from '@mui/material';
import { Clear } from '@mui/icons-material';

const Search = ({ query, onChange, onClear }) => {
    return (
        <TextField 
            label="Search" 
            type="text" 
            variant="standard" 
            fullWidth 
            sx={{mt: {xs: 1, sm: 0}}}
            value={query}
            onChange={onChange}
            InputProps={{
                endAdornment: (
                    <IconButton onClick={onClear}>
                        {query.length > 0 ? <Clear/> : ''}
                    </IconButton>
                )
            }}
        />
    );
}

export default Search;