import { useEffect } from 'react';
import Accordion from "../Accordion";
import SlugSettings from "./SlugSettings";

const Slug = props => {
    const {
        isSlugSettingsExpanded, 
        setSlugSettingsExpanded,
        ...otherProps
    } = props;

    useEffect(() => {
        // Hotkey for toggling expansion of `Advanced` section
        const onKeySDown = e => e.ctrlKey && e.shiftKey && e.code === 'KeyS' && setSlugSettingsExpanded(isSlugSettingsExpanded => !isSlugSettingsExpanded);
        document.addEventListener("keydown", onKeySDown);
        return () => document.removeEventListener("keydown", onKeySDown);
    }, []);

    return (
        <Accordion
            isExpanded={isSlugSettingsExpanded}
            toggleExpanded={() => setSlugSettingsExpanded(!isSlugSettingsExpanded)}
            title='Slug'
        >
            <SlugSettings {...otherProps}/> 
        </Accordion>
    );
}

export default Slug;