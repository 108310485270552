import React, { useState } from 'react';
import { 
    useTheme,
    useMediaQuery,
    Container, 
    Box,
    Typography,
    IconButton,
    Badge,
    Grid,
    darken,
} from '@mui/material';
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import { Icon } from '@iconify/react';
import { MenuRounded } from '@mui/icons-material';
import settingsTabs from './settingsTabs';
import SettingsDrawer from './SettingsDrawer';
import { setSettingsPage } from '../../../slices/settingsPageSlice';

const TabLabels = ({ value, setValue, isExpandedMatch }) => {
    const theme = useTheme();
    const user = useSelector(state => state.user.value);
    const tabs = settingsTabs.filter(tab => {
        return user.isAdmin ? true : !tab?.adminOnly;
    });   
    return (
        <Box>
            {
                tabs.map(current => {
                    return (
                        <Box sx={{ 
                            display: 'flex', alignItems: 'center', 
                            justifyContent: 'flex-start', gap: 4, pl: 1, pt: 2, pb: 2, 
                            cursor: 'pointer', 
                            color: current.id === value && '#4791DB',
                            borderRight: current.id === value && '3px solid #4791DB',
                            backgroundColor: current.id === value && darken(
                                theme.palette.background.default, theme.palette.mode === 'dark' ? 0.125 : 0.06
                            ),
                            '&:hover': {
                                backgroundColor:  darken(
                                        theme.palette.background.default, theme.palette.mode === 'dark' ? 0.125 : 0.06
                                    )
                                }
                            }}
                            onClick={() => setValue(current.id)}
                        >   
                            {
                                <Box sx={{alignSelf: 'center', minWidth: !isExpandedMatch && '40px'}}>
                                    {   
                                        current?.adminOnly ?
                                        <>
                                            <Badge color='#f0a500' badgeContent={
                                                <Icon 
                                                    icon="mdi:crown" 
                                                    color="#f0a500" 
                                                    width="14" 
                                                    height="14"
                                                />
                                            }>
                                                {
                                                    React.cloneElement(
                                                        current.icon,
                                                        { 
                                                            style: {
                                                                fontSize: 28
                                                            } 
                                                        }
                                                    )
                                                }
                                            </Badge>
                                        </>:
                                        <>
                                            {
                                                React.cloneElement(
                                                    current.icon,
                                                    { 
                                                        style: {
                                                            fontSize: 28
                                                        } 
                                                    }
                                                )
                                            }
                                        </>
                                    }
                                </Box>
                            }
                            {   
                                isExpandedMatch &&
                                <Box>
                                    <Typography sx={{width: '100%'}} valiant="h1" component="div">
                                        {current.label}
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    );
                })
            }
        </Box>
    );
}

const Tabs = ({ value, toggleDrawer }) => {

    const theme = useTheme();
    const isExpandedMatch = useMediaQuery(theme.breakpoints.up(1080));
    const tab = settingsTabs.find(tab => { return tab.id === value });

    return (
        <Box sx={{width: '100%', borderLeft: isExpandedMatch && '0.3px solid #AAB4BE'}}>
            {       
                <Box>  
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1}}>
                        {   
                            !tab?.disableTitleIntegration &&
                            <Box sx={{ pt: 1, display: 'flex', alignItems: 'center'}}>
                                {
                                    !isExpandedMatch &&
                                    <Box sx={{pl: 2}}>
                                        <IconButton onClick={toggleDrawer(true)}>
                                            <MenuRounded fontSize='large'/>
                                        </IconButton>
                                    </Box>
                                }
                                <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                    <h1>
                                        { tab.label }
                                    </h1>
                                </Box>
                            </Box>
                        }
                        <Box sx={{p: !tab?.disableTitleIntegration && 2}}>
                            { 
                                React.cloneElement(
                                    tab.component,
                                    { 
                                        toggleDrawer,
                                        isDrawerExpandedMatch: isExpandedMatch 
                                    }
                                )
                            }
                        </Box>
                    </Box>
                </Box>
            }
        </Box>
    );
}

const Settings = () => {

    const theme = useTheme();
    const dispatch = useDispatch();

    const isExpandedMatch = useMediaQuery(theme.breakpoints.up(1080));

    const [openSettingsDrawer, setOpenSettingsDrawer] = useState(false);
    const settingsPage = useSelector(state => state.settingsPage.value);

    const toggleDrawer = open => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
        setOpenSettingsDrawer(open);
    };
   
    return (
        <Container sx={{height: '100%', mb: 10}}>
            <Box sx={{ flexGrow: 1 }}>
                { 
                    isExpandedMatch ?
                    <Grid container>
                        <Grid item xs={2}>
                            <TabLabels 
                                value={settingsPage}
                                setValue={page => dispatch(setSettingsPage(page))}
                                isExpandedMatch={isExpandedMatch}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Tabs value={settingsPage} />
                        </Grid>
                    </Grid>:
                    <Tabs 
                        value={settingsPage} 
                        toggleDrawer={toggleDrawer}
                    /> 
                }
            </Box>
            <SettingsDrawer 
                open={openSettingsDrawer}
                toggleDrawer={toggleDrawer}
                setValue={page => dispatch(setSettingsPage(page))}
            />
        </Container>
    );
}

export default Settings;