import {
    Box,
    InputLabel,
    MenuItem,
    FormControl,
    Select
} from '@mui/material';

const perPageOptions = [10, 25, 50, 100, 250];

const PerPageSwitcher = ({ perPage, handlePerPageChange, variant = "outlined", inputText = "Per page" }) => {    
    return (
        <Box>
            <FormControl variant={variant} sx={{ minWidth: 100 }} size="small">
                {   
                    inputText.length > 0 &&
                    <InputLabel id="select-perpage-small-label">{inputText}</InputLabel>
                }
                <Select
                    labelId="select-perpage-small-label"
                    id="select-perpage-small"
                    value={perPage}
                    label="Per page"
                    onChange={handlePerPageChange}
                    defaultValue={perPage}
                >
                    {
                        perPageOptions.map(item => {
                            return (
                                <MenuItem key={item} value={item}>{item}</MenuItem>
                            );
                        })
                    }
                </Select>
            </FormControl>
        </Box>
    );
}

export default PerPageSwitcher;