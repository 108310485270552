import { 
    Box,
    Button
} from "@mui/material";

const periodOptions = [
    {value: 7, label: "1 week"},
    {value: 31, label: "1 month"},
    {value: 91, label: "3 months"},
    {value: 365, label: "1 year"},
    {value: '', label: "All"},
]

const PeriodOptions = ({ period, setPeriod, styles = {}, size = 'medium'}) => {
    return (
        <Box sx={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2, ...styles}}>
            {
                periodOptions.map(current => {
                    return (
                        <Button 
                            key={current.value}
                            variant={current.value === period.value ? "contained" : "outlined"} 
                            sx={{ borderRadius: '20px' }}
                            onClick={() => setPeriod(current)}
                            size={size}
                        >
                            {current.label}
                        </Button>
                    );
                })
            }
        </Box>
    );
}

export {
    periodOptions,
}

export default PeriodOptions;