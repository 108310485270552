import { useState } from 'react';
import { Box } from '@mui/material';
import Controls from './Controls';
import Actions from './Actions';
import Loader from '../../Loader';
import { QRCodeModal } from '../../general';
import LinkDisplay from '../../account/view/LinkDisplay';

const Result = props => {

    const {
        shortenedUrl, 
        handleClear,
        ...otherProps
    } = props;

    const [loading, setLoading] = useState(false);
    const [openQRCodeModal, setOpenQRCodeModal] = useState(false);

    return (
        <>
        {
            loading ? 
            <Loader/> :
            <Box>
                <Controls 
                    setLoading={setLoading}
                    setOpenQRCodeModal={setOpenQRCodeModal}
                    {...otherProps}
                />
                <Box sx={{mb: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Box sx={{ m: 10, mb: {xs: 0, sm: 2, md: 4}, display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 1}}>
                        <Box sx={{alignSelf: 'center'}}>
                            <h2>Your shortened URL</h2>
                        </Box>
                        <Box sx={{alignSelf: 'center', maxWidth: {xs: '73vw', sm: '65vw', md: '50vw'}}}>
                            <LinkDisplay 
                                link={shortenedUrl}
                                enableCopy={false}
                                withLabel={false}
                            />
                        </Box>
                    </Box>
                    <Actions 
                        shortenedUrl={shortenedUrl}
                        handleClear={handleClear}
                    />
                </Box> 
                <QRCodeModal
                    open={openQRCodeModal}
                    handleClose={() => setOpenQRCodeModal(false)}
                    value={shortenedUrl}
                />
            </Box>
        }
        </>
    );
}

export default Result;