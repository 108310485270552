import { Box } from '@mui/material';
import CustomCode from './CustomCode';
import SmartCode from './SmartCode';
import AutoCode from './AutoCode';

const SlugSettings = props => {
    return(
        <Box sx={{m: 2, mt: 4, display: 'flex', flexDirection: 'column', rowGap: {xs: 2, sm: 1}}}>
            <AutoCode {...props} />
            <SmartCode {...props} />
            <CustomCode {...props} />
        </Box>
    );
}

export default SlugSettings;