import { 
    useState, 
    useEffect 
} from "react";
import { 
    useTheme,
    useMediaQuery,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    TextField,
    IconButton,
} from "@mui/material";
import { Clear } from '@mui/icons-material';
import { BarChart } from '@mui/x-charts/BarChart';
import axios from "axios";
import { useSnackbar } from "notistack";
import { withPather } from "react-pather";
import { 
    PeriodOptions, 
    periodOptions 
} from "./common";
import Loader from "../../../Loader";

const metrics = [
    { label: 'Users', value: 'users' },
    { label: 'Conversions', value: 'conversions' },
    { label: 'Conversions Seperated', value: 'conversions_separated' },
    { label: 'Redirects', value: 'redirects' },
    { label: 'Redirects Successful', value: 'redirects_successful' },
    { label: 'Redirects Failed', value: 'redirects_failed' },
    { label: 'Redirects Separated', value: 'redirects_separated' },
]

const Metrics = ({ pather }) => {

    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [period, setPeriod] = useState(periodOptions[0]);
    const [metric, setMetric] = useState(metrics[0].value);
    const [delta, setDelta] = useState(null);
    
    const [info, setInfo] = useState({});
    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);

    const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));

    const setInfoSafe = updStateObj => {
        setInfo(prevState => ({
            ...prevState,
            ...updStateObj,
        }));    
    }

    useEffect(() => {
        setInfo({});
        series.forEach(serie => {
            setInfoSafe({
                [serie.label]: serie.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0), 
            })
        });
    }, [series]);

    useEffect(() => {
        setLoading(true);
        axios.get(
            pather.back.Settings.metrics, 
            { 
                params: { 
                    metric,
                    delta,
                    period: period.value,
                }
            }
        )
        .then(response => {
            const data = response.data;
            console.log(data);
            setLabels(data?.labels);
            setSeries(data?.series);
        })
        .catch(error => {
            console.log(error);
            const status = error?.response?.data?.status;
            status && enqueueSnackbar(status, { variant: 'error' });
        }).finally(() => {
            setLoading(false);
        });
    }, [metric, period, delta]);

    return (
        <>
            {
                loading ? 
                <Loader/> :
                <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 3}}>
                    <Box sx={{width: '100%', display: 'flex'}}>
                        <Box sx={{width: '100%', display: 'flex', gap: 5, justifyContent: 'center'}}>
                            <Box sx={{ width: '15vw' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-metric-label">Metric</InputLabel>
                                    <Select
                                        labelId="select-metric-label"
                                        id="select-metric-field"
                                        value={metric}
                                        label="Metric"
                                        onChange={e => setMetric(e.target.value)}
                                    >
                                        {
                                            metrics.map(metric => {
                                                return (
                                                    <MenuItem value={metric.value} key={metric.value}>{metric.label}</MenuItem>
                                                );
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <TextField 
                                    label="Delta" 
                                    variant="standard" 
                                    type="number"
                                    value={delta}
                                    onChange={e => setDelta(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={() => setDelta(null)}>
                                                {delta ? <Clear/> : ''}
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <Box sx={{display: 'flex', gap: 5}}>
                                {
                                    Object.keys(info).map((label, index) => {
                                        return (
                                            <Typography variant="h6" component="div">
                                                {label}: {info[label]}
                                            </Typography>
                                        );
                                    })
                                }
                            </Box>
                        </Box>
                        <Box sx={{display: 'flex'}}>
                            <Box>
                                {   
                                    series?.length > 0 && labels?.length > 0 ?
                                    <BarChart
                                        xAxis={[{ scaleType: 'band', data: labels }]}
                                        series={series}
                                        width={matchesDownMd ? (matchesUpSm ? 600 : 450) : 900}
                                        height={matchesDownMd ? (matchesUpSm ? 500 : 350) : 600}
                                    /> :
                                    <Box sx={{height: '50vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Typography variant="h6" component="div">
                                            No data
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                        </Box>
                        <Box>
                            <PeriodOptions 
                                period={period} 
                                setPeriod={setPeriod} 
                                size={matchesDownMd ? 'small' : 'medium'}
                            />
                        </Box>
                    </Box>
                </Box>
            }
        </>
    );
}

export default withPather()(Metrics);