import {
    Box,
    LinearProgress 
} from '@mui/material';
import ViewSwitcher from './ViewSwitcher';
import Search from './Search';
import PerPageSwitcher from './PerPageSwitcher';
import SortByDate from './SortByDate';
import FilterByDate from './FilterByDate';

const ConversionListSettings = ({ 
    queryLoading, 
    numberOfResults, 
    handlePerPageChange,
    settings, 
    setMethods, 
    withViewSwitcher = true 
}) => {
    
    const {
        query,
        mode, 
        freshness, 
        perPage, 
        dateSort
    } = settings;

    const { 
        setQuery,
        setMode,
        setFreshness,
        setStartDate,
        setEndDate,
        setDateSort,
    } = setMethods;

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
            <Box>
                <Search 
                    query={query}
                    onChange={e => setQuery(e.target.value)}
                    onClear={() => setQuery("")}
                />
                {/* FIXME: show loader if loading takes more than 1 sec */}
                { queryLoading && <LinearProgress /> }
            </Box>
            {   
                withViewSwitcher &&
                <Box sx={{mt: 1, width: '100%'}}>
                    <ViewSwitcher 
                        mode={mode} 
                        setMode={newMode => setMode(newMode)} 
                        freshness={freshness}
                        setFreshness={newFreshness => setFreshness(newFreshness)}
                    />
                </Box>
            }
            <Box sx={{mt: 1, width: '100%', display: 'flex', alignItems: {xs: 'flex-start', sm: 'center'}, flexDirection: {xs: 'column', sm: 'row'}, gap: {xs: 2}}}>
                <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center', gap: 2}}>
                    <Box>
                        <span>Number of results: {numberOfResults}</span>
                    </Box>
                    <PerPageSwitcher 
                        perPage={perPage}
                        handlePerPageChange={handlePerPageChange}
                    />
                </Box>
                <Box sx={{flexGrow: 3, display: 'flex', justifyContent: 'center'}}>
                    <FilterByDate 
                        setStartDate={date => setStartDate(date)}
                        setEndDate={date => setEndDate(date)}
                        settings={settings}
                    />
                </Box>
                <Box sx={{flexGrow: 1, display: 'flex', justifyContent: 'flex-end'}}>
                    <SortByDate 
                        dateSort={dateSort} 
                        onChange={() => setDateSort(dateSort === 'down' ? 'up' : 'down')}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default ConversionListSettings;