import {
    useState,
    useEffect
} from 'react';
import { 
    useTheme,
    useMediaQuery,
    Box,
    Pagination
} from '@mui/material';
import axios from 'axios';
import { withPather } from 'react-pather';
import Loader from '../../../Loader';
import { useEffectExceptOnMount } from '../../../../hooks';
import RedirectsHistoryTopBar from './RedirectsHistoryTopBar';
import RedirectHistoryRow from './RedirectHistoryRow';

const RedirectHistory = ({ pather, code }) => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const [error, setError] = useState('');
    const [redirectsLoading, setRedirectsLoading] = useState(false);

    const [redirects, setRedirects] = useState([]);
    const [redirectsOffset, setRedirectsOffset] = useState(0);
    const [redirectsPerPage, setRedirectsPerPage] = useState(25);
    const [redirectsTotalPages, setRedirectsTotalPages] = useState(0);
    const [redirectsNumOfResults, setRedirectsNumOfResults] = useState(0);
    
    const fetchRedirects = (success = null) => {
        setRedirectsLoading(true);
        axios.get(
            pather.reverse(
                pather.back.Conversion.redirects, 
                { code },
            ),
            {
                params: {
                    offset: redirectsOffset,
                    perPage: redirectsPerPage,
                }, 
            }   
        )
        .then(response => {
            const data = response?.data;
            if (data) {
                setRedirects(data?.redirects);
                setRedirectsTotalPages(data?.total_pages);
                setRedirectsNumOfResults(data?.number_of_results);
            };
            success && success();
        })
        .catch(error => {
            console.log(error);
            const status = error?.data?.status;
            if(status) setError(status);
            else {
                if (error?.response?.status === 404) setError('Resource is not found!');
                else setError('Something went wrong!');
            }
        })
        .finally(() => {
            setRedirectsLoading(false);
        });
    }

    useEffect(() => {
        fetchRedirects();
    }, []);

    useEffectExceptOnMount(() => {
        fetchRedirects();
    }, [redirectsOffset]);

    useEffectExceptOnMount(() => {
        fetchRedirectsWithOffsetReset();
    }, [redirectsPerPage]);

    const fetchRedirectsWithOffsetReset = () => {
        fetchRedirects(() => {
            setRedirectsOffset(0);
        });
    }

    const handleOffsetChange = (event, value) => setRedirectsOffset(value - 1);
    const handleRedirectsPerPageChange = event => setRedirectsPerPage(event.target.value);

    return (
        <>
            {
                redirectsLoading ? 
                <Loader/> :
                <>
                    {   
                        error ? 
                        <Box sx={{
                            display: 'flex', width: '100%', justifyContent: 'center', 
                            alignItems: 'center', minHeight: '320px'
                        }}>
                            <h3>{error} &#128546;</h3>
                        </Box> :
                        <>
                            {   
                                redirects && redirects.length > 0 &&
                                <Box sx={{mt: 2, width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 1}}>
                                    <RedirectsHistoryTopBar
                                        redirectsNumOfResults={redirectsNumOfResults}
                                        fetchRedirectsWithOffsetReset={fetchRedirectsWithOffsetReset}
                                        redirectsPerPage={redirectsPerPage}
                                        handleRedirectsPerPageChange={handleRedirectsPerPageChange}
                                    />
                                    <Box>
                                        {
                                            redirects.map((redirect, index) => (
                                                <RedirectHistoryRow 
                                                    redirect={redirect} 
                                                    index={index}
                                                />
                                            ))
                                        }
                                    </Box>
                                </Box>
                            }
                            {   
                                redirectsTotalPages > 1 && 
                                <Box sx={{width: '100%', mt: 3, display: 'flex', justifyContent: 'center'}}>
                                    <Pagination
                                        count={redirectsTotalPages} 
                                        page={redirectsOffset + 1} 
                                        variant="outlined" 
                                        color="primary" 
                                        size={matches ? "medium" : "large"}
                                        onChange={handleOffsetChange}
                                    />
                                </Box>
                            }
                        </>
                    }
                </>
            }
        </>
    );
}

export default withPather()(RedirectHistory);