import { createSlice } from '@reduxjs/toolkit'

export const settingsPageSlice = createSlice({
    name: 'settingsPage',
    initialState: {
      value: 0,
    },
    reducers: {
      setSettingsPage: (state, action) => {
        state.value = action.payload;
      },
      resetSettingsPage: (state) => {
        state.value = 0;
      },
    }
})

export const { setSettingsPage, resetSettingsPage } = settingsPageSlice.actions

export default settingsPageSlice.reducer;
