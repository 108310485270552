import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loader = ({ boxMinHeight, loaderSize }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: boxMinHeight }}>
            <CircularProgress size={loaderSize}/>
        </Box>
    );
}

Loader.defaultProps = {
    boxMinHeight: '250px',
    loaderSize: 50,
}

export default Loader;