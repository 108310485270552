import _ from 'lodash';
import { 
    Box, 
    IconButton,
    Tooltip,
} from '@mui/material';
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import {
    ClearAllRounded,
    MenuRounded,
} from '@mui/icons-material';
import ConversionListViewer from '../../general/ConversionListViewer';
import { 
    setQuery, 
    setPerPage, 
    setOffset, 
    setDateSort,
    setStartDate, 
    setEndDate, 
    resetForgottenSettings, 
    defaultSettings 
} from '../../../../slices/forgottenSettingsSlice';

const Forgotten = ({ toggleDrawer, isDrawerExpandedMatch }) => {

    const dispatch = useDispatch();

    const forgottenSettings = useSelector(state => state.forgottenSettings.value);

    const _setQuery = val => dispatch(setQuery(val));
    const _setPerPage = val => dispatch(setPerPage(val));
    const _setOffset = val => dispatch(setOffset(val));
    const _setDateSort = val => dispatch(setDateSort(val));
    const _setStartDate = val => dispatch(setStartDate(val));
    const _setEndDate = val => dispatch(setEndDate(val));

    return (
        <Box sx={{ml: 2, mr: 2}}>
            <ConversionListViewer 
                settings={forgottenSettings}
                setMethods={{
                    setQuery: _setQuery,
                    setPerPage: _setPerPage,
                    setOffset: _setOffset,
                    setDateSort: _setDateSort,
                    setStartDate: _setStartDate,
                    setEndDate: _setEndDate,
                }}
                settingsTopBar={
                    <ForgottenTopBar 
                        isDrawerExpandedMatch={isDrawerExpandedMatch}
                        toggleDrawer={toggleDrawer}
                    />
                }
                conversionRowOptions={{
                    highlightBorderBottom: false,
                    withDelete: true,
                    dateSettings: { 
                        title: 'Date of forgetting', 
                        field: 'markedAt',
                    }
                }}
                withViewSwitcher={false}
            />
        </Box>
    );
}

const ForgottenTopBar = ({ isDrawerExpandedMatch, toggleDrawer }) => {
    const dispatch = useDispatch();
    const forgottenSettings = useSelector(state => state.forgottenSettings.value);
    return (
        <Box sx={{ pt: 1, display: 'flex', alignItems: 'center'}}>
            {
                !isDrawerExpandedMatch &&
                <Box sx={{pl: 2}}>
                    <IconButton onClick={toggleDrawer(true)}>
                        <MenuRounded fontSize='large'/>
                    </IconButton>
                </Box>
            }
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                    <Box>
                        <h1>Forgotten</h1>
                    </Box>
                    {
                        !_.isEqual(forgottenSettings, defaultSettings) &&
                        <Box>
                            <Tooltip title="Reset settings" placement="right">
                                <IconButton onClick={() => dispatch(resetForgottenSettings())}>
                                    <ClearAllRounded />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default Forgotten;