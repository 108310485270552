
const Conversion = {
    path: '/conversion',
    isSection: true,
    sub: {
        code: {
            path: '/:code',
            sub: {
                regenerate: '/regenerate',
                deactivate: '/deactivate',
                delete: '/delete',  
                redirects: '/redirects',  
                checkRedirectPasscode: '/check-passcode',
                forget: '/forget',
                redirectData: '/redirect-data',
                favourite: '/favourite',
            }
        },
        shorten: '/shorten',
    }
}

export default Conversion;