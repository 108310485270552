import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { 
    Box,
    IconButton,
    Tooltip,
} from '@mui/material';
import { withPather } from 'react-pather';
import ReplayIcon from '@mui/icons-material/Replay';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

const Controls = props => {

    const { 
        pather, 
        favourite, 
        setFavourite, 
        code, 
        handleRegenerate, 
        setShowResult, 
        setLoading, 
        setOpenQRCodeModal 
    } = props;

    const navigate = useNavigate();

    const user = useSelector(state => state.user.value);

    const toggleFavourite = () => {
        setLoading(true);
        axios.post(
            pather.reverse(pather.back.Conversion.favourite, { code })
        )
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
            setFavourite(!favourite);
        });
    }

    return (
        <Box sx={{mt: 1, display: 'flex', alignItems: 'center'}}>
            <Box>
                <Tooltip title="Back">
                    <IconButton onClick={() => setShowResult(false)}>
                        <ArrowBackIosNewIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box sx={{marginLeft: 'auto'}}>
                <Tooltip title="Regenerate">
                    <IconButton onClick={handleRegenerate}>
                        <ReplayIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="QR code">
                    <IconButton onClick={() => setOpenQRCodeModal(true)}>
                        <QrCode2Icon />
                    </IconButton>
                </Tooltip>
                {
                    user.isLogined &&
                    <>
                        <Tooltip title="Favourite">
                            <IconButton onClick={toggleFavourite}>
                                {   favourite ?
                                    <StarIcon /> :
                                    <StarBorderIcon />
                                }
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Open">
                            <IconButton onClick={() => navigate(pather.reverse(pather.front.view, { code }))}>
                                <OpenInNewRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            </Box>
        </Box>
    );
}

export default withPather()(Controls);