import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const DeadLink = ({ advanced, setAdvancedSafe }) => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Box>
                <FormControlLabel
                    checked={advanced.isDead}
                    control={<Checkbox/>}
                    label="Dead link"
                    labelPlacement="end"
                    onChange={e => setAdvancedSafe({isDead: e.target.checked})}
                />
            </Box>
            <Box>
                <Typography component="div" sx={{fontSize: '12px'}}>
                    Link that always will be in loading state.
                </Typography>
            </Box>
        </Box>
    );
}

export default DeadLink;