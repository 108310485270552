import SystemRoutes from './SystemRoutes';
import Header from '../components/header';
import Footer from '../components/footer';
import ScrollToTopButton from '../components/general/ScrollToTopButton';

const Router = () => {
    return (
        <>
            <Header />
            <SystemRoutes />
            <ScrollToTopButton />
            <Footer />
        </>
    );
}

export default Router;