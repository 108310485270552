import Box from '@mui/material/Box';
import { withPather } from 'react-pather';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import PasswordRoundedIcon from '@mui/icons-material/PasswordRounded';
import FeatureRow from './FeatureRow';
import Loader from '../Loader';
import LinkDisplay from '../account/view/LinkDisplay';

const Features = ({ pather }) => {
    return (
        <Box> 
            <FeatureRow 
                desc={
                    <>Indefinite validity - shortened link without expiration date that will always be valid.</>
                }
                image={
                    <span style={{fontSize: '5rem'}}>&infin;</span>
                }
            />
            <FeatureRow 
                reverse={true}
                mddesc={7}
                mdimage={5}
                desc={
                    <>SmartCode feature is an opportunity to create a unique slug for your shortened link based on the source URL itself using a keyword extraction mechanism. This slug will preserve the informativeness of the source link, so it will be easier to realize where this link leads for those who are going to use it.</>
                }
                image={
                    <Box sx={{width: '95%'}}>
                        <LinkDisplay 
                            link={`${window.location.origin}${pather.reverse(pather.front.main, { code: 'google-search' })}`}
                            enableCopy={false}
                        />
                    </Box>
                }
            />
            <FeatureRow 
                desc={
                    <>Use passcode protection to secure your shortened link. <br/>Whenever a redirect happens, the user will be obliged to enter <br/> a passcode to proceed to a destination link.</>
                }
                image={
                    <PasswordRoundedIcon style={{ fontSize: '5rem' }}/>
                }
            />
            <FeatureRow 
                reverse={true}
                desc={
                    <>DeadLink - link that is always in a loading state. By managing this option, you can control your link reachability.</>
                }
                image={
                    <Loader boxMinHeight='100%' loaderSize='3.5rem'/>
                }
            />
            <FeatureRow 
                mddesc={7}
                mdimage={5}
                desc={
                    <>Custom codes - don't want a random short code? <br/> Create your slug using the custom code feature.</>
                }
                image={
                    <Box sx={{width: '95%'}}>
                        <LinkDisplay 
                            link={`${window.location.origin}${pather.reverse(pather.front.main, { code: 'my-unique-slug' })}`}
                            enableCopy={false}
                        />
                    </Box>
                }
            />
            <FeatureRow 
                reverse={true}
                desc={
                    <>Limit the maximum amount of redirects by setting MaxRedirects field during the shortening setup. Link deactivates after a specified number of redirects is reached.</>
                }
                image={
                    <span style={{fontSize: '3rem'}}>&#8815; 3</span>
                }
            />
            <FeatureRow 
                desc={
                    <>Hide source link after shortened link expires? Or maybe make it visible to others? <br/> Manage Revealable option to control this behaviour.</>
                }
                image={
                    <VisibilityOffRoundedIcon style={{ fontSize: '3rem' }}/>
                }
            />
        </Box>
    );
}

export default withPather()(Features);