import { RadioExtended } from "../../general";

const reservedSlugMode = 'autocode';

const AutoCode = ({ slugMode, setSlugMode }) => {
    return (
        <RadioExtended
            currentValue={slugMode}
            value={reservedSlugMode}
            setValue={setSlugMode}
            title='AutoCode'
            desciption='Random alphanumeric code will be generated automatically.'
            radioName='radio-buttons-code-type' 
        />
    );
}

export {
    reservedSlugMode
}

export default AutoCode;