import { 
    useTheme,
    Box,
    FormControl,
    FormLabel,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';

const Period = ({ days, handleDaysChange }) => {
    const theme = useTheme();
    return (
        <Box className="container-card">
            <Box className="square" sx={{ display:'flex', justifyContent: 'center', alignItems: 'center', boxShadow: theme.palette.squareBoxShadow}}>
                <FormControl sx={{width: {xs: '75%', sm: '70%'}, height: '75%'}}>
                    <FormLabel sx={{mb: 2, fontSize: '20px'}}>Active period</FormLabel>
                    <TextField
                        id="outlined-number"
                        label="Amount of days"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={days}
                        onChange={handleDaysChange}
                        variant="standard" 
                    />
                    <PeriodOptions 
                        days={days} 
                        handleDaysChange={handleDaysChange} 
                    />
                </FormControl>
            </Box>
        </Box>
    );
}

const periodOptions = [
    {value: '', label: "Indefinite"},
    {value: 1, label: "1 day"},
    {value: 3, label: "3 days"},
    {value: 7, label: "1 week"},
    {value: 31, label: "1 month"},
    {value: 91, label: "3 months"},
    {value: 183, label: "6 months"},
    {value: 365, label: "1 year"},
    {value: 1095, label: "3 years"},
    {value: 1825, label: "5 years"},
]

const PeriodOptions = ({ days, handleDaysChange }) => {

    const getChunkPeriodOptions = ({ start = 0, end = undefined }) => {
        return (
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                {
                    periodOptions.slice(start, end).map(current => {
                        return (
                            <FormControlLabel 
                                control={<Radio />} 
                                key={current.value}
                                value={current.value} 
                                label={current.label} 
                            />
                        );
                    })
                }
            </Box>
        );
    }

    const getAllOptions = () => {
        return (
            <>
                { getChunkPeriodOptions({ end: 5 }) }
                { getChunkPeriodOptions({ start: 5 }) }
            </>
        );
    }

    return (
        <RadioGroup
            value={days}
            onChange={handleDaysChange}
            sx={{mt: 2}}
        >   
            <Box sx={{display: 'flex', justifyContent: 'space-around'}}>
                { getAllOptions() }
            </Box>
        </RadioGroup>
    );
}

export {
    periodOptions,
}

export default Period;