import { Grid, Box } from '@mui/material';

const FeatureRow = ({ reverse = false, desc, image, mddesc = 9, mdimage = 3 }) => {

    let first = (
        <Grid item md={mddesc} xs={12}>
            <Box sx={{display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <Box>
                    <h3>{desc}</h3>
                </Box>  
            </Box>
        </Grid>
    )

    let second = (
        <Grid item md={mdimage} xs={12}>
            <Box sx={{display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <Box>
                    {image}
                </Box>
            </Box>
        </Grid>
    )

    if (reverse) [first, second] = [second, first]

    return (
        <Box sx={{mt: {xs: 3, md: 0}, pb: {xs: 2, md: 0}, width: '100%', minHeight: '30vh', display: 'flex', alignItems: 'center', borderBottom: '1px solid grey'}}>
            <Grid container spacing={2}>
                {first}
                {second}
            </Grid>
        </Box>
    );
}

export default FeatureRow;