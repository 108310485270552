import _ from 'lodash';
import {
    Box,
    Button,
    IconButton,
    Tooltip
} from '@mui/material';
import { ClearAllRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { 
    useSelector, 
    useDispatch
} from 'react-redux';
import { withPather } from 'react-pather';
import { 
    defaultSettings,
    resetDashboardSettings 
} from '../../../slices/dashboardSettingsSlice';

const TopBar = ({ pather }) => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const dashboardSettings = useSelector(state => state.dashboardSettings.value);
    const resetSettings = () => dispatch(resetDashboardSettings());
    
    return (
        <Box sx={{display: 'flex', alignItems: {xs: 'start', sm: 'center'}, flexDirection: {xs: 'column', sm: 'row'}}}>
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <Box>
                    <h1>Dashboard</h1>
                </Box>
                {
                    !_.isEqual(dashboardSettings, defaultSettings) &&
                    <Box>
                        <Tooltip title="Reset settings" placement="right">
                            <IconButton onClick={resetSettings}>
                                <ClearAllRounded />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
            </Box>
            <Box sx={{marginLeft: {sm: 'auto'}}}>
                <Button variant="outlined" onClick={() => navigate(pather.front.shorten)} sx={{
                    ':hover': {
                        bgcolor: 'primary.main', // theme.palette.primary.main
                        color: 'white',
                    },
                }}>New Shortening</Button>
            </Box>
        </Box>
    );
}

export default withPather()(TopBar);