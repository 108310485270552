import { 
    useState, 
    useEffect, 
    useMemo 
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { withPather } from 'react-pather';
import Highlighter from "react-highlight-words";
import { 
    useTheme, 
    useMediaQuery, 
    Grid,
    Box,
    Button,
    Link, 
    Tooltip
} from '@mui/material';
import {
    ContentCopyRounded,
    DeleteForeverRounded,
    DoneRounded,
} from '@mui/icons-material';
import { InfoChips } from '../../general';
import { setConversion } from '../../../../slices/conversionSlice';
import { Confirmation } from '../../../general';

const defaultDateSettings = { 
    title: 'Creation date', 
    field: 'createdAt',
}

const ConversionRow = ({ 
    pather, 
    conversion, 
    settings, 
    handleDelete, 
    highlightBorderBottom = true, 
    withDelete = false,
    dateSettings = defaultDateSettings 
}) => {

    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const url = new URL(conversion.source);
    const composedUrl = `${window.location.origin}${pather.reverse(pather.front.main, { code: conversion.code })}`;

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [copied, setCopied] = useState(false);
    const { query, freshness } = settings;

    useEffect(() => {
        if(!copied) return;
        const intervalId = setInterval(() => {
            setCopied(false);
        }, 1000)
        return () => clearInterval(intervalId);
    }, [copied]);

    const isBigCodeBelowMd = useMemo(() => () => matchesDownMd && conversion.code.length > 7, [matchesDownMd, conversion]);

    const copy = e => {
        if(copied) setCopied(false);
        navigator.clipboard.writeText(composedUrl);
        setCopied(true);
        e.stopPropagation();
    }

    const view = () => {
        dispatch(setConversion(conversion));
        navigate(pather.reverse(pather.front.view, { code: conversion.code }));
    }

    const {
        title, 
        field
    } = dateSettings;

    const date = {
        title,
        value: conversion[field]
    }

    return (
        <Box sx={{
                borderBottom: '1px solid grey', pb: 2, pt: 3, 
                cursor: 'pointer', 
                '&:hover': { 
                    borderBottom: highlightBorderBottom && freshness === 'all' && !conversion.markers.isActive && '2px solid #DA2D2D' 
                },
                display: 'flex',
            }} 
            className="data-row" 
            onClick={view}
        >
            <Grid container>
                <Grid item xs={withDelete ? 10 : 12}>
                    <Box sx={{
                        width: '100%', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: 2
                    }}>
                        <Box sx={{
                            display: 'flex', alignItems: 'center', 
                            flexDirection: withDelete || isBigCodeBelowMd() ? 'column' : 'row', gap: (withDelete || isBigCodeBelowMd()) && 2 
                        }}>
                            <Box sx={{
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 1, 
                                fontSize: '26px', 
                                maxWidth: {xs: '100%', md: '70%'}, 
                                alignSelf: (withDelete || isBigCodeBelowMd()) && 'flex-start' 
                            }}>
                                <Box sx={{overflowX: 'auto', colorScheme: theme.palette.mode === 'dark' ? 'dark' : 'light', whiteSpace: 'nowrap'}}>
                                    <Link 
                                        href={composedUrl}
                                        target="_blank" rel="noreferrer" 
                                        sx={{
                                            ...theme.link, 
                                            cursor: 'pointer',
                                        }}
                                        onClick={e => e.stopPropagation()}
                                    >
                                        <Highlighter
                                            highlightStyle={{color: '#F98404', backgroundColor: 'transparent'}}
                                            searchWords={[query]}
                                            autoEscape={true}
                                            textToHighlight={ matches ? conversion.code : composedUrl }
                                        />
                                    </Link>
                                </Box>
                                <Box>
                                    {
                                        copied ?
                                        <DoneRounded fontSize="small" sx={{color: 'green'}} onClick={e => e.stopPropagation()}/>  :
                                        <ContentCopyRounded fontSize='small' onClick={copy}/>
                                    }
                                </Box>
                            </Box>
                            <Box sx={{marginLeft: (!withDelete && !isBigCodeBelowMd()) && 'auto', alignSelf: (withDelete || isBigCodeBelowMd()) && 'flex-start'}}>
                                <Tooltip title={date.title} placement={withDelete ? 'right' : 'bottom'}>
                                    <span>
                                        {
                                            matchesDownMd ?
                                            new Date(date.value).toLocaleDateString() : 
                                            date.value
                                        }
                                    </span>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box sx={{maxWidth: {xs: '100%', md: '70%'}, overflow: 'hidden'}}>
                            {/* FIXME: wrong display of cyrilic characters  */}
                            <Highlighter
                                highlightStyle={{color: '#F98404', backgroundColor: 'transparent'}}
                                searchWords={[query]}
                                autoEscape={true}
                                textToHighlight={ url.origin + url.pathname }
                            />
                        </Box>
                        <InfoChips size="small" conversion={conversion} />
                    </Box>
                </Grid>
                {   
                    withDelete &&
                    <Grid item xs={2}>
                        <Box sx={{
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'flex-end', 
                            height: '99%',
                        }}>
                            <Button 
                                variant="contained" 
                                sx={{
                                    height: '4.25rem', 
                                    width: '4.25rem',  
                                    backgroundColor: '#BF3131',
                                    borderRadius: '10px',
                                    '&:hover': {
                                        backgroundColor: '#7D0A0A',
                                    }
                                }}
                                onClick={e => {
                                    setOpenConfirmDelete(true);
                                    e.stopPropagation();
                                }}
                            >
                                <DeleteForeverRounded sx={{fontSize: '1.7rem'}} />
                            </Button>
                        </Box>
                    </Grid>
                }
            </Grid>
            <Confirmation 
                open={openConfirmDelete}
                setOpen={setOpenConfirmDelete}
                title="Conversion deletion"
                description={
                    `Are you sure you want to delete conversion '${conversion.code}' forever? This action can not be reverted.`
                }
                success={e => {
                    handleDelete(conversion.code);
                    e.stopPropagation();
                }}
            />
        </Box>
    );
}

export default withPather()(ConversionRow);