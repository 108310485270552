import { createSlice } from '@reduxjs/toolkit'


const getThemeFromLocalStorage = () => {
  const theme = localStorage.getItem("theme");
  return !!theme ? theme : 'light';
}


export const themeSlice = createSlice({
    name: 'theme',
    initialState: {
      value: getThemeFromLocalStorage()
    },
    reducers: {
      setTheme: (state, action) => {
        state.value = action.payload;
      },
      toggleTheme: (state) => {
        state.value = state.value === 'light' ? 'dark': 'light';
        localStorage.setItem("theme", state.value);
      },
    }
})

export const { setTheme, toggleTheme } = themeSlice.actions

export default themeSlice.reducer;