import { createSlice } from '@reduxjs/toolkit';

export const defaultSettings = {
  query: '',
  perPage: 25,
  offset: 0,
  freshness: 'active',
  mode: 'history',
  dateSort: 'down',
  startDate: null,
  endDate: null,
}

export const dashboardSettingsSlice = createSlice({
    name: 'dashboardSettings',
    initialState: {
      value: defaultSettings
    },
    reducers: {
      setQuery: (state, action) => {
        state.value.query = action.payload;
      },
      setPerPage: (state, action) => {
        state.value.perPage = action.payload;
      },
      setOffset: (state, action) => {
        state.value.offset = action.payload;
      },
      setFreshness: (state, action) => {
        state.value.freshness = action.payload;
      },
      setMode: (state, action) => {
        state.value.mode = action.payload;
      },
      setDateSort: (state, action) => {
        state.value.dateSort = action.payload;
      },
      setStartDate: (state, action) => {
        state.value.startDate = action.payload;
      },
      setEndDate: (state, action) => {
        state.value.endDate = action.payload;
      },
      resetDashboardSettings: (state, action) => {
        state.value = defaultSettings;
      }
    }
})

export const { 
  setQuery,
  setPerPage,
  setOffset,
  setFreshness,
  setMode,
  setDateSort,
  resetDashboardSettings,
  setStartDate,
  setEndDate,
} = dashboardSettingsSlice.actions;

export default dashboardSettingsSlice.reducer;