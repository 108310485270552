import { useState } from 'react';
import { 
    useTheme, 
    Container, 
    Box, 
    TextField, 
    Link, 
    Button, 
} from '@mui/material';
import { withPather } from 'react-pather';
import { useNavigate, Navigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import ActivationMessage from './ActivationMessage';
import Loader from '../Loader';
import { PasswordField } from '../general';
import useToken from '../auth/useToken';
import { setUser } from '../../slices/userSlice';
import { setToken as setUUT } from '../../slices/tokenSlice';
import { setUserLoading } from '../../slices/userLoadingSlice';

const Login = ({ pather }) => {

    const theme = useTheme();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { setToken } = useToken();
    const currentUser = useSelector(state => state.user.value);
    const isUserLoading = useSelector(state => state.userLoading.value); 
    const uut = useSelector(state => state.token.value);

    const [isActivated, setIsActivated] = useState(null);
    const [loading, setLoading] = useState(false);
    const [authData, setAuthData] = useState({
        login: '',
        password: '',
    });

    const setAuthDataSafe = (updStateObj) => {
        setAuthData((prevState) => ({
            ...prevState,
            ...updStateObj,
        }));    
    }

    // Password visibility
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    //

    const isInputValid = () => {
        if (!!!authData.login.trim()){
            enqueueSnackbar('Login field is empty!', { variant: 'error' });
            return false;
        }
        else if (!!!authData.password.trim()) {
            enqueueSnackbar('Password field is empty!', { variant: 'error' });
            return false;
        }
        return true;
    }

    const handleSubmit = () => {
        if(!isInputValid()) return;
        setLoading(true);
        dispatch(setUserLoading(true));
        axios.post(
            pather.back.Auth.login, 
            authData,    
        )
        .then(response => {
            const data = response.data;
            dispatch(setUser({
                isLogined: true,
                isConfirmed: data.is_confirmed,
                email: data.email,
                username: data.username,
                uut: data.uut,
                isAdmin: data.is_admin,
            }));
            setToken(data.access_token);
            if(uut !== data.uut) dispatch(setUUT(data.uut));
            navigate(pather.front.dashboard);
        })
        .catch(({ response: { data } }) => {
            enqueueSnackbar(data.status, { variant: 'error' });
            console.log(data.status);
            if (data?.is_activated === false) setIsActivated(false);
        })
        .finally(() => {
            dispatch(setUserLoading(false));
            setLoading(false);
        });
    }

    const sendActivationLink = () => {
        setLoading(true);
        axios.post(
            pather.back.confirmationLink, 
            {
                login: authData.login,
            },    
        )
        .then(resp => {
            const data = resp?.data;
            enqueueSnackbar(data?.status, { variant: 'success' });
            setIsActivated(null);
        })
        .catch(resp => {
            const data = resp?.response?.data;
            enqueueSnackbar(data?.status, { variant: 'error' });
            console.log(data);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    if (isUserLoading) return <Loader/>;
    else if (currentUser?.isLogined) return <Navigate to={pather.front.dashboard} />;

    return (
        <Container sx={{height: '100%'}}>
            {
                loading ? 
                <Loader/> :
                <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', mt: 4, mb: 4, }}>
                    {   
                        isActivated === false  &&
                        <ActivationMessage sendActivationLink={sendActivationLink}/>
                    }
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <h1>Login</h1>
                    </Box>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', rowGap: 2, width: {xs: '100%', sm: '70%', md: '30vw'}}}>
                            <TextField 
                                label="Username or email" 
                                sx={{width: '100%'}}
                                value={authData.login}
                                onChange={(e) => setAuthDataSafe({login: e.target.value.trim()})} 
                            /> 
                            <PasswordField
                                label='Password'
                                value={authData.password} 
                                handleChange={e => setAuthDataSafe({password: e.target.value})} 
                                show={showPassword} 
                                handleShow={handleClickShowPassword}
                            />
                            <Box>
                                <Button 
                                    variant='contained' 
                                    sx={{width: '100%', pt: 1, pb: 1}}
                                    onClick={handleSubmit}
                                >
                                    Login
                                </Button>
                            </Box>
                            <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                Don't have an account? &nbsp; <Link href="#" onClick={() => navigate(pather.front.signup)} sx={theme.link}>Sign up</Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        </Container>
    );
}

export default withPather()(Login);