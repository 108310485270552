import {    
    useTheme, 
    Box, 
    Typography, 
    IconButton 
} from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const Accordion = ({ isExpanded, toggleExpanded, title, children }) => {
    const theme = useTheme();
    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Box className="square" sx={{
                mt: '2vw', width: '90%', height: '100%', p: 3, // mt: '4vw',
                cursor: 'pointer', boxShadow: theme.palette.squareBoxShadow
            }}>
                <Box sx={{display: 'flex', alignItems: 'center'}} onClick={toggleExpanded}>
                    <Box>
                        <IconButton>
                        {
                            isExpanded ? 
                            <KeyboardArrowDownRoundedIcon sx={{ fontSize: '1.3rem' }} /> : 
                            <ArrowForwardIosRoundedIcon sx={{ fontSize: '0.9rem' }} />
                        }
                        </IconButton>
                    </Box>
                    <Typography className='noselect' sx={{color: theme.palette.text.main, alignSelf: 'flex-end', fontSize: '18px'}}>
                        {title}
                    </Typography>
                </Box>
                <Box>
                    { 
                        isExpanded && children
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default Accordion;