import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import LinkDisplay from '../account/view/LinkDisplay';
import { useTheme } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { withPather } from 'react-pather';

const RedirectFailure = ({ pather, source }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <Box sx={{width: '100%', mt: 10, minHeight: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center',  flexWrap: 'wrap', flexDirection: 'column'}}>
            <Box sx={{fontSize: {md: 26, xs: 20}}}>Current link is invalid &#128546;</Box>
            <Box sx={{mt: 2, fontSize: {md: 22, xs: 16}}}>
                Try to generate new one <Link href="#" onClick={() => navigate(pather.front.shorten)} sx={theme.link}>here</Link>
            </Box>
            {
                !!source &&
                <Box sx={{mt: '3vh', md: '3vh', width: {xs: '86%', sm: '93%', md: '97%'}}}>
                    <LinkDisplay 
                        link={source}
                        label='Source link was :'
                    />
                </Box>
            }
        </Box>
    );
}

export default withPather()(RedirectFailure);