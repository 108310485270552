import { 
    useTheme,
    Box,
    TextField,
 } from '@mui/material';

const URLInput = ({ url, setUrl }) => {
    const theme = useTheme();
    return (
        <Box className="container-card">
            <Box className="square" sx={{
                height: '100%', 
                width: {sm: '60vw', xs: '100%'},  
                p: 2,
                pt: 1.5,
                boxShadow: theme.palette.squareBoxShadow,
                zIndex: 1,
                borderRadius: '25px'
            }}>
                <TextField 
                    fullWidth 
                    label="URL" 
                    id="source_url"
                    multiline
                    maxRows={7}
                    placeholder="https://some/very/long/url"
                    onChange={e => setUrl(e.target.value.trim())}
                    value={url}
                    variant="standard"
                />
            </Box>
        </Box>
    );
}

export default URLInput;