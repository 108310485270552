import {
    Box
} from '@mui/material';
import {
    KeyboardArrowUpRounded,
    KeyboardArrowDownRounded
} from '@mui/icons-material';

const SortByDate = ({ dateSort, onChange }) => {
    return (
        <Box 
            className="noselect" 
            sx={{
                padding: '5px', cursor: 'pointer', 
                display: 'flex', gap: 2, 
                alignItems: 'center', 
                '&:hover': {filter: 'brightness(70%)'},
                pl: 0,
            }}
            onClick={onChange}
        >
            <Box>
                <span>Sort by date</span>
            </Box>
            <Box>
                { 
                    dateSort === 'down' ?
                    <KeyboardArrowDownRounded/> :
                    <KeyboardArrowUpRounded/> 
                }
            </Box>
        </Box>
    );
}

export default SortByDate;