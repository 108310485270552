import { 
    useTheme, 
    useMediaQuery, 
    Box,
    Button,
    Modal
} from '@mui/material';
import { Download } from '@mui/icons-material';
import { QRCode } from 'react-qrcode-logo';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 250, sm: 300, md: 400 },
    bgcolor: 'background.paper',
    border: '2px solid #1e2229',
    borderRadius: '10px',
    boxShadow: 24,
    p: 1,
};

const QRCodeModal = ({ open, handleClose, value }) => {

    const theme = useTheme();

    const matchesXs = useMediaQuery(theme.breakpoints.only('xs'));
    const matchesSm = useMediaQuery(theme.breakpoints.only('sm'));

    const qrCodeSize = (matchesXs ? style.width.xs: (matchesSm ? style.width.sm : style.width.md)) - 20;

    const download = () => {
        const canvas = document.getElementById("link-qrcode");
        if(canvas) {
            const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl
            downloadLink.download = `shortl_qrcode.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    }
 
    return (
        <Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="QR code"
                aria-describedby="QR code for shortened link"
            >
                <Box sx={style}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                        <Box>
                            <QRCode
                                logoImage='/apple-touch-icon.png'
                                id="link-qrcode"
                                size={qrCodeSize}
                                removeQrCodeBehindLogo={true}
                                logoOpacity={1}
                                logoPadding={1}
                                value={value}
                                qrStyle="dots"
                                eyeRadius={10}
                                logoPaddingStyle='circle'
                                ecLevel='Q'
                            />
                        </Box>
                        <Box>
                            <Button 
                                variant="outlined" 
                                sx={{width: '100%'}} 
                                endIcon={<Download />}
                                onClick={download}
                            >
                                Download
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default QRCodeModal;