import { Box, Button } from '@mui/material';

// TODO: Merge with AccountConfirmation component
const ActivationMessage = ({ sendActivationLink }) => {
    return (
        <Box sx={{width: {xs: '92%', sm: '97%'}}}>
            <Box className="attention" sx={{display: 'flex', 
                width: '100%', alignItems: 'center', justifyContent: 'center', mb: 4, 
                fontSize: '1.1rem', p: 2,
                backgroundColor: '#E84545', color: 'white', borderRadius: '20px',
                flexDirection: {xs: 'column', sm: 'row'}, gap: {xs: 2, sm: 0}
            }}>
                <Box>
                    Activate your account clicking on a link in email
                </Box>
                <Button 
                    variant='contained' 
                    color="secondary"
                    sx={{backgroundColor: '#942c2c', marginLeft: 'auto', width: {xs: '100%', sm: 'auto'}}}
                    onClick={sendActivationLink}
                >
                    Send new link
                </Button>
            </Box>
        </Box>
    );
}

export default ActivationMessage;