import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
import axios from 'axios';
import Loader from "../Loader";
import { withPather } from 'react-pather';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setUser } from '../../slices/userSlice';
import { useSelector } from 'react-redux';

const ConfirmAccount = ({ pather }) => {
    
    const { uut } = useParams();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.user.value);

    useEffect(() => {
        setLoading(true);
        axios.post(
            pather.reverse(pather.back.confirmAccount, { uut })
        ).then(resp => {
            const data = resp.data;
            console.log(data);
            dispatch(setUser({
                ...currentUser,
                isConfirmed: true,
            }));
        }).catch(resp => {
            const data = resp?.response?.data;
            setError(data?.status);
            console.log(data);
        }).finally(() => {
            setLoading(false);
        })
    }, [])

    return (
        <Container sx={{height: '100%'}}>
            {
                loading ?
                <Loader/> :
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '250px', fontSize: '36px', flexDirection: 'column' }}>
                    {
                        error ? 
                        <> 
                            <Box>
                                {error}
                            </Box>
                        </> :
                        <>
                            <Box> 
                                Account successfully confirmed
                            </Box>
                        </>
                    }
                </Box>
            }
        </Container>
    );
}

export default withPather()(ConfirmAccount);