import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { withPather } from 'react-pather';

const StartNow = ({ pather }) => {
    const navigate = useNavigate();
    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, minHeight: '60vh'}}>
                <Box>
                    <h1>Start using Shortl for free today &#9889;</h1>
                </Box>
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <Button 
                        variant="contained"
                        onClick={() => navigate(pather.front.shorten)}
                        sx={{p: 2, borderRadius: '30px', width: {md: '25vw',  sm: '80%', xs: '100%'}, fontWeight: 'bold'}}
                    >
                        Start
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default withPather()(StartNow);