import { useSnackbar } from 'notistack';
import { 
    Box,
    Button 
} from '@mui/material';

const Actions = ({ shortenedUrl, handleClear }) => {
    const { enqueueSnackbar } = useSnackbar();
    return (
        <Box sx={{display: 'flex', justifyContent: 'space-around', width: {md: '60vw', xs: '100%'}}}>
            <ActionBtn 
                variant="outlined" 
                label="Copy link"
                action={() => { 
                    navigator.clipboard.writeText(shortenedUrl); 
                    enqueueSnackbar('Link copied!', { variant: 'success' })
                }}
            />
            <ActionBtn 
                variant="contained"
                label="Clear"
                action={handleClear}
            />
        </Box>
    );
}

const ActionBtn = ({ variant, label, action }) => {
    return (
        <Button 
            variant={variant} 
            sx={{
                p: 1, 
                borderRadius: '30px', 
                width: {
                    xs: '40vw',
                    sm: '30vw',
                    md: '20vw', 
                }
            }} 
            onClick={action}
        >
            {label}
        </Button>
    );
}

export default Actions;