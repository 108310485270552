import { useState } from 'react';
import _ from "lodash";
import { 
    useTheme,
    useMediaQuery,
    Box, 
    Button,
    Typography,
    Tooltip 
} from '@mui/material'
import { HelpRounded } from '@mui/icons-material';
import SwitchableBlock from './SwitchableBlock';
import { PasswordField } from '../../general';

const Locking = ({ advanced, setAdvancedSafe, additional, setAdditionalSafe }) => {

    const theme = useTheme();
    
    const { locking } = additional;

    return (
        <SwitchableBlock
            switcher={locking} 
            handleSwitch={e => {
                setAdvancedSafe({ passcode: e.target.checked ? '' : null });
                setAdditionalSafe({locking: e.target.checked});
            }}
            label={
                <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                    <Typography valiant="h1" component="div">
                        Enable passcode protection
                    </Typography>
                    <Box>
                        <Tooltip title='Redirect will be allowed only if the correct passcode is entered.' placement="right">
                            <HelpRounded fontSize='small' sx={{color: theme.palette.mode === 'light' && '#757575'}} />
                        </Tooltip>
                    </Box>
                </Box>
            }
            field={
                <CustomLockingModeExpanded
                    advanced={advanced}
                    setAdvancedSafe={setAdvancedSafe}
                />
            }
        />
    );
} 

const CustomLockingModeExpanded = ({ advanced, setAdvancedSafe }) => {
    const theme = useTheme();
    const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const [showPasscode, setShowPasscode] = useState(false);
    const handleClickShowPasscode = () => setShowPasscode(show => !show);

    // Random alphanumeric password
    const generateRandomPasscode = (length = 7) => _.times(length, () => _.random(35).toString(36)).join('');
    const generatePasscode = () => setAdvancedSafe({ passcode: generateRandomPasscode() });

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, mt: 0.5}}>
            <Box sx={{display: 'flex', alignItems: 'flex-end', gap: 2}}>
                <PasswordField
                    label='Passcode'
                    value={advanced.passcode}
                    handleChange={e => setAdvancedSafe({ passcode: e.target.value })}
                    show={showPasscode} 
                    handleShow={handleClickShowPasscode}
                    style={{width: {xs: '100%', md: '30vw'}}}
                    variant="standard"
                />
                <Box sx={{cursor: 'pointer'}}>
                    <Button size={matchesDownMd ? "small" : "medium"} variant="outlined" onClick={generatePasscode}>
                        Generate
                    </Button>
                </Box>
            </Box>
            <Typography component="div" sx={{fontSize: '12px'}}>
                Passcode must contain at least 5 characters.
            </Typography>
        </Box>
    );
}

export default Locking;