import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";

const PageNotFound = () => {
    return (
        <Container sx={{height: '100%'}}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '250px', fontSize: '36px', flexDirection: 'column' }}>
                <Box>
                    Page is not found &#129488;
                </Box>
                <Box sx={{fontSize: '24px', mt: 2}}>
                    Return to <Link to='/'>main</Link>
                </Box>
            </Box>
        </Container>
    );
}


export default PageNotFound;