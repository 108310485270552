import { 
    useState, 
    useEffect 
} from 'react';
import { 
    Box,
    useTheme, 
    Link
} from "@mui/material";
import {
    DoneRounded,
    ContentCopyRounded
} from '@mui/icons-material';

const LinkDisplay = ({ link, label, enableCopy = true, withLabel = true }) => {

    const theme = useTheme();
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if(!copied) return;
        const intervalId = setInterval(() => {
            setCopied(false);
        }, 1000)
        return () => clearInterval(intervalId);
    }, [copied])

    const copy = () => {
        navigator.clipboard.writeText(link);
        setCopied(true);
    }

    return (
        <Box sx={{mb: 3}}>
            { 
                withLabel &&
                <Box>
                    <h3>{label}</h3>
                </Box>
            }
            <Box sx={{
                border: `1px solid ${theme.palette.mode === 'dark' ? '#1e2229' : '#AED2FF'}`,
                borderRadius: '20px',
                p: 1.5,
                backgroundColor: theme.palette.mode === 'dark' ? '#222831' : '#E4F1FF',
                width: '100%',
                display: 'flex',
                gap: 1,
            }}>
                <Box>
                    {
                        enableCopy &&
                        <>
                        {
                            copied ?
                            <DoneRounded sx={{color: 'green'}}/> :
                            <ContentCopyRounded onClick={copy} sx={{cursor: 'pointer'}}/>
                        }
                        </>
                    }
                </Box>
                <Box sx={{width: '100%'}}>
                    <Box sx={{ 
                        fontSize: '20px', 
                        overflowWrap: 'break-word', 
                        maxHeight: '35vh', 
                        overflow: 'auto', 
                        width: !enableCopy ? '100%' : {md: '97%', sm: '93%', xs: '90%'}, 
                        colorScheme: theme.palette.mode === 'dark' ? 'dark' : 'light',
                    }}>
                        <Link href={link} target="_blank" rel="noreferrer" sx={theme.link}>
                            {link}
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default LinkDisplay;