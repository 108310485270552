import {
    Grid,
    Box
} from '@mui/material';
import ModeSwitcher from './ModeSwitcher';
import FreshnessSwitcher from './FreshnessSwitcher';

const ViewSwitcher = ({ mode, setMode, freshness, setFreshness }) => {
    return (
        <Box sx={{width: '100%'}}>
            <Grid container spacing={{xs: 2, md: 4}}>
                <Grid item xs={12} md={5}>
                   <ModeSwitcher 
                        mode={mode}
                        setMode={setMode}
                    />
                </Grid>
                <Grid item xs={12} md={7}>
                    <FreshnessSwitcher
                        freshness={freshness}
                        setFreshness={setFreshness}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default ViewSwitcher;