import {
    FormGroup,
    FormControlLabel, 
    Checkbox,
}from '@mui/material';
import { RadioExtended } from '../../../../general';
import { reservedSlugMode as reservedAutoCodeSlugMode } from '../../../../shorten/slug/AutoCode';
import { reservedSlugMode as reservedSmartCodeSlugMode } from '../../../../shorten/slug/SmartCode';
import { reservedSlugMode as reservedCustomCodeSlugMode } from '../../../../shorten/slug/CustomCode';

const reservedChartType = 'separate'

const SeparateChart = props => {
    return (
        <RadioExtended
            value={reservedChartType}
            title='Separate'
            expandedBlock={
                <SeparateChartExpanded {...props}/>
            }
            radioName='radio-buttons-chart-type' 
            alignItemsWrapper='flex-start'
            {...props}
        />
    );
}

const SeparateChartExpanded = ({ separateChartTypes, setSeparateChartTypesSafe }) => {
    return (
        <FormGroup>
            <FormControlLabel 
                control={
                    <Checkbox 
                        checked={separateChartTypes.includes(reservedAutoCodeSlugMode)}
                        onChange={e => setSeparateChartTypesSafe(e.target.checked, reservedAutoCodeSlugMode)}
                    />
                } 
                label="AutoCode" 
            />
            <FormControlLabel 
                control={<Checkbox 
                    checked={separateChartTypes.includes(reservedSmartCodeSlugMode)}
                    onChange={e => setSeparateChartTypesSafe(e.target.checked, reservedSmartCodeSlugMode)}
                />} 
                label="SmartCode"
            />
            <FormControlLabel 
                control={<Checkbox 
                    checked={separateChartTypes.includes(reservedCustomCodeSlugMode)}
                    onChange={e => setSeparateChartTypesSafe(e.target.checked, reservedCustomCodeSlugMode)}
                />} 
                label="CustomCode"
            />
        </FormGroup>
    );
}

export {
    reservedChartType
}

export default SeparateChart;