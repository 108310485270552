import { StaticDatePicker as MuiStaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTheme, Box } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';

const StaticDatePicker = ({ days, setDays }) => {

    const theme = useTheme();

    const handleDateChange = value => {
        const dif = Math.abs(value - new Date());
        const _days = dif / (1000 * 3600 * 24);
        setDays(Math.ceil(_days));
    }

    const addDays = (date, days) => new Date(date.getTime() + days*24*60*60*1000);
    
    return (
        <Box className="container-card">
            <Box className="square" sx={{ 
                    display:'flex', 
                    justifyContent: 'center', 
                    boxShadow: theme.palette.squareBoxShadow,
                    overflow: 'hidden',
                }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <MuiStaticDatePicker
                        label="Valid until"
                        slotProps={{
                            textField: {
                                helperText: 'DD/MM/YYYY. By default shortened URL will be saved for 100 days',
                            },
                            actionBar: { actions: [] }
                        }}
                        sx={{backgroundColor: theme.palette.background.default}}
                        disablePast={true}
                        value={days ? dayjs(addDays(new Date(), days)): null}
                        onChange={handleDateChange}
                        orientation="portrait"
                    />
                </LocalizationProvider>
            </Box>
        </Box>
    );
}

export default StaticDatePicker;