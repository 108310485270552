import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { withPather } from "react-pather";
import Loader from "../components/Loader";

// TODO: Redirect user to /login immediately in private routes after token expiration
const PrivateRoute = ({ pather }) => {

    const user = useSelector(state => state.user.value);
    const isUserLoading = useSelector(state => state.userLoading.value);

    if(isUserLoading) return <Loader />

    if (user?.username) 
        return <Outlet/>;

    return <Navigate to={pather.front.login} />;
};

export default withPather()(PrivateRoute);