import { useSelector, useDispatch } from 'react-redux';
import { setJWT, unsetJWT } from '../../slices/jwtSlice';

const useToken = () => {

  const dispatch = useDispatch();

  const token = useSelector(state => state.jwt.value);

  const saveToken = userToken => dispatch(setJWT(userToken));
  const removeToken = () => dispatch(unsetJWT());

  return {
    setToken: saveToken,
    token,
    removeToken
  }
}

export default useToken;