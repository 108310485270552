
const Settings = {
    path: '/settings',
    isSection: true,
    sub: {
        user: {
            path: '/user',
            isSection: true,
            sub: {
                deactivate: '/deactivate'
            }
        },
        statistics: '/statistics', 
        contact: '/contact', 
        metrics: '/metrics', 
        trends: '/trends', 
    },
}

export default Settings;