import { RadioExtended } from '../../../../general';

const reservedChartType = 'combined'

const CombinedChart = props => {
    return (
        <RadioExtended
            value={reservedChartType}
            title='Combined'
            desciption='All conversions'
            radioName='radio-buttons-chart-type' 
            {...props}
        />
    );
}

export {
    reservedChartType
}

export default CombinedChart;