import { useState } from 'react';
import {
    useTheme,
    Container,
    Box,
    TextField,
    Button,
    Link
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { withPather } from 'react-pather';
import Loader from '../Loader';
import PasswordField from '../general/PasswordField';
import { validateEmail } from '../../utils';

const SignUp = ({ pather, }) => {

    const theme = useTheme();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const currentUser = useSelector(state => state.user.value);
    const isUserLoading = useSelector(state => state.userLoading.value); 

    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [showConfirmMsg, setShowConfirmMsg] = useState('');

    const [user, setUser] = useState({
        username: '',
        email: '',
        password: '',
    });

    const setUserSafe = (updStateObj) => {
        setUser((prevState) => ({
            ...prevState,
            ...updStateObj,
        }));    
    }

    // Password visibility
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPasswordConfirmation = () => setShowPasswordConfirmation((show) => !show);
    //

    const isInputValid = () => {
        if (!user.username || !user.email || !user.password) {
            enqueueSnackbar('Some fields are not filled!', { variant: 'error' });
            return false;
        }
        else if (user.password !== passwordConfirmation) {
            enqueueSnackbar('Passwords do not match!', { variant: 'error' });
            return false;
        }
        else if(!validateEmail(user.email)){
            enqueueSnackbar('Invalid email!', { variant: 'error' });
            return false;
        }        
        return true;
    }

    const handleSubmit = () => {
        setLoading(true);
        if (!isInputValid()) {
            setLoading(false);
            return;
        }
        axios.post(
            pather.back.Auth.register, 
            user,    
        )
        .then(response => {
            const data = response.data;
            enqueueSnackbar(data.status, { variant: 'success' });
            setShowConfirmMsg(true);
        })
        .catch(response => {
            const data = response?.response?.data;
            enqueueSnackbar(data?.status ? data?.status : 'Invalid input!', { variant: 'error' });
            console.log(response);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    if (isUserLoading) return <Loader/>;
    else if (currentUser?.isLogined) navigate(pather.front.dashboard);
    
    return (
        <Container sx={{height: '100%'}}>
            {
                loading ? 
                <Loader/> :
                showConfirmMsg ?  // TODO: Move 'Please activate your account' page to seperate route
                <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', mt: 4, mb: 4, }}>
                        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <h2>Please activate your account clicking on a link in the email</h2>
                    </Box>
                    <Box sx={{fontSize: '24px', mt: 2, display: 'flex', justifyContent: 'center'}}>
                        Go to&nbsp;<Link href={pather.front.login} sx={theme.link}>
                            login
                        </Link>
                    </Box>  
                </Box>:
                <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', mt: 4, mb: 4, }}>
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <h1>Sign up</h1>
                    </Box>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', rowGap: 2, width: {xs: '100%', sm: '70%', md: '30vw'}}}>
                            <TextField 
                                label="Username" 
                                value={user.username} 
                                onChange={(e) => setUserSafe({username: e.target.value})} 
                                sx={{width: '100%'}}
                            />
                            <TextField 
                                label="Email" 
                                sx={{width: '100%'}}
                                value={user.email} 
                                onChange={(e) => setUserSafe({email: e.target.value})} 
                            /> 
                            <PasswordField
                                label='Password'
                                value={user.password} 
                                handleChange={(e) => setUserSafe({password: e.target.value})} 
                                show={showPassword} 
                                handleShow={handleClickShowPassword}
                            />
                            <PasswordField
                                label='Confirm password'
                                value={passwordConfirmation} 
                                handleChange={(e) => setPasswordConfirmation(e.target.value)} 
                                show={showPasswordConfirmation} 
                                handleShow={handleClickShowPasswordConfirmation}
                                error={passwordConfirmation && user.password !== passwordConfirmation }
                                helperText={passwordConfirmation && user.password !== passwordConfirmation && "Passwords do not match"}
                            />
                            <Box>
                                <Button 
                                    variant='contained' 
                                    sx={{width: '100%', pt: 1, pb: 1}}
                                    onClick={handleSubmit}
                                >
                                    Sign up
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        </Container>
    );
}

export default withPather()(SignUp);