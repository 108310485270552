import { useState } from 'react';
import {
    Box,
    Button,
    Modal,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    Typography
} from '@mui/material';
import { PasswordField } from '../../general';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs: '70vw', sm: 400},
    bgcolor: 'background.paper',
    border: '2px solid #1e2229',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
};

const EditModal = ({ open, setOpen, conversion, handleEdit, advanced, setAdvancedSafe }) => {
    
    // Passcode edit field visibility
    const [showPasscodeEdit, setShowPasscodeEdit] = useState(false);
    const handleClickShowPasscodeEdit = () => setShowPasscodeEdit(show => !show);
    //
    
    const close = () => {
        setOpen(false);
        setShowPasscodeEdit(false);
        setAdvancedSafe({
            maxRedirects: conversion.maxRedirects,
            revealable: conversion.revealable,
            isDead: conversion.isDead,
            passcode: conversion.passcode,
        })
    }
    
    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', gap: 2}}>
                    <Box>
                        <Box>
                            <h3>Edit {conversion.code}</h3>
                        </Box>
                        <Box>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox 
                                        checked={advanced.revealable} 
                                        onChange={e => setAdvancedSafe({ revealable: e.target.checked })}
                                    />
                                } label="Revealable" />
                                { 
                                    conversion.markers.isActive &&
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                                        <FormControlLabel control={
                                            <Checkbox 
                                                checked={advanced.isDead} 
                                                onChange={e => setAdvancedSafe({ isDead: e.target.checked })}
                                            />
                                        } label="isDead" />
                                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                                            <Box>
                                                <Typography valiant="h1" component="div">
                                                    Max number of redirects: 
                                                </Typography>
                                            </Box>
                                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                                <TextField
                                                    id="outlined-number-redirects"
                                                    label="Redirects"
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="standard" 
                                                    sx={{ml: 3, width: {sm: '200px'}}}
                                                    value={advanced.maxRedirects}
                                                    onChange={e => {
                                                        const val = e.target.value;
                                                        setAdvancedSafe({
                                                            maxRedirects: !!val ? val : null
                                                        });
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                                            <Box>
                                                <Typography valiant="h1" component="div">
                                                    Passcode: 
                                                </Typography>
                                            </Box>
                                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                                <PasswordField
                                                    label='Passcode'
                                                    value={advanced.passcode} 
                                                    handleChange={e => {
                                                        const val = e.target.value;
                                                        setAdvancedSafe({
                                                            passcode: !!val ? val : ''
                                                        });
                                                    }}
                                                    show={showPasscodeEdit} 
                                                    handleShow={handleClickShowPasscodeEdit}
                                                    variant="standard" 
                                                    style={{width: {sm: '200px'}}}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            </FormGroup>
                        </Box>
                    </Box>
                    <Box sx={{ ml: 'auto', display: 'flex', gap: 1}}>
                        <Button variant="outlined" onClick={close}>Cancel</Button>
                        <Button variant="contained" onClick={handleEdit}>Modify</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default EditModal;