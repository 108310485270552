import { configureStore } from '@reduxjs/toolkit'
import {
  themeReducer,
  tokenReducer,
  userReducer,
  conversionReducer,
  jwtReducer,
  dashboardSettingsReducer,
  ratingReducer,
  userLoadingReducer,
  forgottenSettingsReducer,
  settingsPageReducer,
} from './slices';

const store = configureStore({
  reducer: {
    theme: themeReducer,
    token: tokenReducer,
    user: userReducer,
    conversion: conversionReducer,
    jwt: jwtReducer,
    dashboardSettings: dashboardSettingsReducer,
    rating: ratingReducer,
    userLoading: userLoadingReducer,
    forgottenSettings: forgottenSettingsReducer,
    settingsPage: settingsPageReducer,
  }
})

export default store;