import { 
    Box,
    Button,
    Modal,
    Typography, 
    IconButton 
} from '@mui/material';
import { Close } from '@mui/icons-material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs: '70vw', sm: 400},
    bgcolor: 'background.paper',
    border: '2px solid #1e2229',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
};

const Confirmation = ({ open, setOpen, title, description, success }) => {
    const close = e => {
        setOpen(false);
        e.stopPropagation();
    }
    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClick={e => e.stopPropagation()}
        >
            <Box sx={style}>
                <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', gap: 2}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                        <Box sx={{display: 'flex', alignItems: 'baseline'}}>
                            <Box>
                                <Typography variant="h6" component="div" fontWeight='fontWeightBold'>
                                    {title}
                                </Typography>
                            </Box>
                            <Box sx={{marginLeft: 'auto'}}>
                                <IconButton 
                                    sx={{cursor: 'pointer'}}
                                    onClick={close}
                                >
                                    <Close fontSize='small' />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box>
                            <span>{description}</span>
                        </Box>
                    </Box>
                    <Box sx={{ ml: 'auto', display: 'flex', gap: 1}}>
                        <Button variant="outlined" onClick={close}>Cancel</Button>
                        <Button variant="contained" onClick={success}>Confirm</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default Confirmation;