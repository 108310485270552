import Button from '@mui/material/Button';
import { useNavigate, useLocation } from "react-router-dom";
import { withPather } from 'react-pather';

const LoginButton = ({ pather }) => {
    const navigate = useNavigate();
    const location = useLocation();
    if (location.pathname === pather.front.login) return;
    return (
        <Button 
            onClick={() => navigate(pather.front.login)}
            variant="outlined" 
            sx={{width: '100%', display: 'flex', justifyContent: 'center', p: 1.5, pl: 4, pr: 4, borderRadius: '30px'}}
        >
            Login
        </Button>
    );
}

export default withPather()(LoginButton);