import { useEffect } from 'react';
import AdvancedSettings from './AdvancedSettings';
import Accordion from '../Accordion';

const Advanced = props => {    
    const {
        isAdvancedExpanded, 
        setAdvancedExpanded,
        ...otherProps
    } = props;

    useEffect(() => {
        // Hotkey for toggling expansion of `Advanced` section
        const onKeyADown = e => e.ctrlKey && e.shiftKey && e.code === 'KeyA' && setAdvancedExpanded(isAdvancedExpanded => !isAdvancedExpanded);
        document.addEventListener("keydown", onKeyADown);
        return () => document.removeEventListener("keydown", onKeyADown);
    }, []);

    return (
        <Accordion
            isExpanded={isAdvancedExpanded}
            toggleExpanded={() => setAdvancedExpanded(!isAdvancedExpanded)}
            title='Advanced'
        >
            <AdvancedSettings {...otherProps}/> 
        </Accordion>
    );
}

export default Advanced;