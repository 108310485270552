import {
    Box,
    TextField,
    Typography,
    CircularProgress,
    Tooltip
} from '@mui/material';
import {
    Done,
    Close,
    PriorityHigh
} from '@mui/icons-material';
import { RadioExtended } from '../../general';

const reservedSlugMode = 'customcode';

const CustomCode = ({ slugMode, setSlugMode, ...props }) => {
    return (
        <RadioExtended
            currentValue={slugMode}
            value={reservedSlugMode}
            setValue={setSlugMode}
            title='CustomCode'
            expandedBlock={
                <CustomCodeExpanded {...props}/>
            }
            radioName='radio-buttons-code-type' 
            hideTitleWhenExpanded={true}
        />
    );
}

const CustomCodeExpanded = props => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', rowGap: '10px'}}>
            <CustomCodeInput {...props}/>
            <CustomCodeDescription />
        </Box>
    );
}

const CustomCodeInput = ({ advanced, setAdvancedSafe, additional, loadingCustomCodeCheck, isCustomCodeInvalid, isPathOccupied }) => {
    return (
        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
            <CustomCodeInputField 
                advanced={advanced} 
                setAdvancedSafe={setAdvancedSafe} 
                additional={additional}
            />
            <CustomCodeAvailabilityChecker 
                additional={additional}
                loadingCustomCodeCheck={loadingCustomCodeCheck}
                isCustomCodeInvalid={isCustomCodeInvalid}
                isPathOccupied={isPathOccupied}
            />
        </Box>
    );
}

const CustomCodeInputField = ({ advanced, setAdvancedSafe }) => {
    return (
        <Box sx={{display: 'flex', alignItems: {xs: 'flex-start', sm: 'flex-end'}, flexDirection: {xs: 'column', sm: 'row'}, gap: {xs: 1}}}>
            <Box>
                <Typography component="div">
                    Custom code: 
                </Typography>
            </Box>
            <Box>
                <TextField
                    id="outlined-number-custom-code"
                    InputLabelProps={{ shrink: true }}
                    variant="standard" 
                    sx={{ ml: {sm: 3} }}
                    value={advanced.customCode}
                    onChange={e => setAdvancedSafe({ customCode: e.target.value })}
                />  
            </Box>
        </Box>
    );
}

const CustomCodeAvailabilityChecker = ({ additional, loadingCustomCodeCheck, isCustomCodeInvalid, isPathOccupied }) => {
    return (
        <Box sx={{display: 'flex', alignItems: 'center', ml: 1}}>
            <>
            {
                loadingCustomCodeCheck ?
                <CircularProgress size={18}/>:
                <>
                {
                    additional.isCustomCodeAvailable !== null ? 
                    <>
                        {
                            additional.isCustomCodeAvailable ?
                            <Tooltip title="Code is available" placement="right">
                                <Done fontSize="inherit" sx={{color: 'green'}} /> 
                            </Tooltip>:
                            <Tooltip title="Code is unavailable" placement="right">
                                <Close fontSize="inherit" sx={{color: 'red'}} />
                            </Tooltip>
                        }
                    </> :   
                    <>
                        {
                            isPathOccupied &&
                            <Tooltip title="Reserved" placement="right">
                                <Close fontSize="inherit" sx={{color: 'red'}} />
                            </Tooltip>
                        }
                        {   
                            !isPathOccupied && isCustomCodeInvalid && 
                            <Tooltip title="Code can contain only A-Za-z0-9-_ characters and must have a length between 5 and 100" placement="right">
                                <PriorityHigh fontSize="inherit" sx={{color: 'red'}} />
                            </Tooltip>
                        }
                    </>
                }
                </>
            }
            </>
        </Box>
    );
}

const CustomCodeDescription = () => {
    return (
        <Box>
            <Typography component="div" sx={{fontSize: '12px'}}>
                Custom code can contain only A-Za-z0-9-_ characters and must have a length between 5 and 100.<br/> 
                Link with custom code can be active maximum for 100 days.
            </Typography>
        </Box>
    );
}

export {
    reservedSlugMode
}

export default CustomCode;