import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { withPather } from 'react-pather';
import { Box, Container } from '@mui/material';
import HoverRating from './HoverRating';
import { UUT, UUTLC } from '../../config';
import { setToken } from '../../slices/tokenSlice';
import { setRating, resetRating } from '../../slices/ratingSlice';

const Footer = ({ pather }) => {

    const dispatch = useDispatch();

    const [isRated, setIsRated] = useState(false);
    const rating = useSelector(state => state.rating.value);
    const userLoading = useSelector(state => state.userLoading.value);

    useEffect(() => {
        // Rating restoration
        if(userLoading) return;
        axios.get(
            pather.back.rating
        ).then(response => {
            if (response.data.last_rating) dispatch(setRating(response.data.last_rating));
            else dispatch(resetRating());
        })
        .catch(error => {
            console.log(error);
        });
    }, [userLoading]);

    const changeRate = value => {
        axios.post(
            pather.back.rating, 
            { rating: value },
        ).then(response => {
            dispatch(setRating(value));
            const uut = localStorage.getItem(UUTLC);
            if(!uut){
                dispatch(setToken(response.data.token));
                axios.defaults.headers.common[UUT] = response.data.token;
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    return (
        <Container sx={{height: '100%'}}>
            <Box>
                <Box>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px', flexDirection: 'column'}}>
                        {   isRated ?
                            <Box>Thank you for your feedback!</Box>
                            :
                            <>
                                <Box>Rate us please</Box>
                                <HoverRating rating={rating} setRating={changeRate} setIsRated={setIsRated}/>
                            </>
                        }
                    </Box>
                </Box>
                <Box>
                    <Box sx={{m: 2}}>
                        <Box sx={{mb: 6, minHeight: '14vh', display: 'flex', alignItems: 'flex-end', justifyContent: 'center', borderTop: '0.3px solid #AAB4BE'}}>
                            <Box>
                                Copyright © {new Date().getFullYear()} Shortl
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    );

}

export default withPather()(Footer);