import { 
    Box,
    Typography,
    Radio
} from '@mui/material';

// Either `description` or `expandedBlock` should be provided
const RadioExtended = ({ 
    currentValue,
    value, 
    setValue, 
    title, 
    desciption, 
    expandedBlock,
    radioName,
    hideTitleWhenExpanded = false,
    alignItemsWrapper = 'center',
}) => {
    return (
        <Box sx={{display: 'flex', alignItems: currentValue === value ? alignItemsWrapper : 'center', gap: 2}}>
            <Radio
                checked={currentValue === value}
                onChange={e => setValue(e.target.value)}
                value={value}
                name={radioName}
                inputProps={{ 'aria-label': value }}
            />
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 0.5}}>
                {   
                    currentValue !== value ?
                    <Typography component="div">
                        {title}
                    </Typography> :
                    <>
                        {
                            !hideTitleWhenExpanded &&
                            <Typography component="div">
                                {title}
                            </Typography>   
                        }
                    </> 
                }
                {   
                    currentValue === value &&
                    <>
                        {
                            desciption ?
                            <Typography component="div" sx={{fontSize: '12px'}}>
                                {desciption}
                            </Typography> :
                            <>
                                {expandedBlock}
                            </>
                        }
                    </>
                }
            </Box>
        </Box>
    );
}

export default RadioExtended;