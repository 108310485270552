import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ShortcutRoundedIcon from '@mui/icons-material/ShortcutRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import { withPather } from 'react-pather';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';

const HeaderMenu = ({ pather, anchorEl, handleClose, logout }) => {

    const navigate = useNavigate();
    const user = useSelector(state => state.user.value);
    const theme = useTheme();

    const click = func => {
        func();
        handleClose();
    }

    return (
        <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{ onMouseLeave: handleClose }}
            sx={{
                '.MuiMenu-paper': {
                    backgroundColor: theme.palette.mode === 'dark' && '#121212',
                }
            }}
        >
            <MenuItem onClick={() => click(() => navigate(pather.front.dashboard))}>
                <Box sx={{display: 'flex', alignItems: 'center', width: '100%', gap: 3}}>
                    <Box>Dashboard</Box> 
                    <Box sx={{marginLeft: 'auto'}}>
                        <DashboardRoundedIcon fontSize='large' />
                    </Box>
                </Box>
            </MenuItem>
            <MenuItem onClick={() => click(() => navigate(pather.front.shorten))}>
                <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                    <Box>Shorten</Box> 
                    <Box sx={{marginLeft: 'auto'}}>
                        <ShortcutRoundedIcon fontSize='large' />
                    </Box>
                </Box>
            </MenuItem>
            {/* <MenuItem onClick={handleClose}>
                <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                    <Box>Plan</Box> 
                    <Box sx={{marginLeft: 'auto'}}>
                        <PaidIcon fontSize='large' />
                    </Box>
                </Box>
            </MenuItem> */}
            <MenuItem onClick={() => click(() => navigate(pather.front.settings))}>
                <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                    <Box>Settings</Box> 
                    <Box sx={{marginLeft: 'auto'}}>
                        <SettingsIcon fontSize='large' />
                    </Box>
                </Box>
            </MenuItem>
            {
                user && user?.isAdmin &&
                <MenuItem onClick={() => click(() => window.open(pather.back.admin, '_blank'))}>
                    <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                        <Box>Admin</Box> 
                        <Box sx={{marginLeft: 'auto'}}>
                            <AdminPanelSettingsRoundedIcon fontSize='large' />
                        </Box>
                    </Box>
                </MenuItem>
            }
            <MenuItem onClick={logout}>
                <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                    <Box>Log out</Box> 
                    <Box sx={{marginLeft: 'auto'}}>
                        <LoginRoundedIcon fontSize='large' />
                    </Box>
                </Box>
            </MenuItem>
        </Menu>
    );
} 

export default withPather()(HeaderMenu);