/**
 * Every time when `currentUser` restoration happens before it 
 * `userLoading` state value should be set to `true` and 
 * after it to `false`. This is necessary to synchronize 
 * dependent components (e.g. rating in Footer component).
 */

import { createSlice } from '@reduxjs/toolkit';

export const userLoadingSlice = createSlice({
    name: 'userLoading',
    initialState: {
      value: true,
    },
    reducers: {
      setUserLoading: (state, action) => {
        state.value = action.payload;
      },
      toggleUserLoading: (state, action) => {
        state.value = !state.value;
      },
    }
});

export const { 
    setUserLoading,
    toggleUserLoading 
} = userLoadingSlice.actions;

export default userLoadingSlice.reducer;