import { createSlice } from '@reduxjs/toolkit';


export const conversionSlice = createSlice({
    name: 'conversion',
    initialState: {
      value: {},
    },
    reducers: {
      setConversion: (state, action) => {
        state.value = action.payload;
      },
    }
})

export const { setConversion } = conversionSlice.actions;

export default conversionSlice.reducer;