import React from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { 
  LocalizationProvider, 
  MobileDatePicker
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const FilterByDate = ({ setStartDate, setEndDate, settings }) => {

  const { 
      startDate, 
      endDate,
  } = settings;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', gap: 1}}>
        <MobileDatePicker
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
          slotProps={{ 
            actionBar: {
              actions: ['clear', 'cancel']
            },
            textField: { 
              size: 'small',
            },
          }}
          disableFuture
          clearable
          minDate={dayjs('01-01-2022')}
        />
        <MobileDatePicker
          label="End Date"
          value={endDate}
          onChange={setEndDate}
          slotProps={{ 
            actionBar: {
              actions: ['clear', 'cancel']
            },
            textField: { 
              size: 'small',
            },
          }}
          disableFuture
          minDate={dayjs('01-01-2022')}
        />
      </Box>
    </LocalizationProvider>
  );
}

export default FilterByDate;