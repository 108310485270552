import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

const FreshnessSwitcher = ({ freshness, setFreshness }) => {
    return (
        <ButtonGroup
            disableElevation
            sx={{width: '100%'}}
        >
            <Button 
                sx={{borderTopLeftRadius: '20px', borderEndStartRadius: '20px', width: {xs: '100%', md: '100%'}, p: 1}} 
                onClick={() => {
                    setFreshness('active');
                }}
                variant={freshness === 'active' ? 'contained' : 'outlined'}
            >
                Active
            </Button>
            <Button 
                sx={{ width: {xs: '100%', md: '100%'}, p: 1}} 
                onClick={() => {
                    setFreshness('all');
                }}
                variant={freshness === 'all' ? 'contained' : 'outlined'}
            >
                All
            </Button>
            <Button 
                sx={{borderTopRightRadius: '20px', borderEndEndRadius: '20px', width: {xs: '100%', md: '100%'}, p: 1}} 
                onClick={() => setFreshness('inactive')}
                variant={freshness === 'inactive' ? 'contained' : 'outlined'}
            >
                Inactive
            </Button>
        </ButtonGroup>
    );
}

export default FreshnessSwitcher;