import { 
    useState,    
    useEffect 
} from 'react';
import { 
    useTheme,
    useMediaQuery,
    Box, 
    Typography
} from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { withPather } from 'react-pather';
import axios from 'axios';
import 
    SeparateChart, 
    { reservedChartType as reservedSeparateChartType }  
from './SeparateChart';
import 
    CombinedChart, 
    { reservedChartType as reservedCombinedChartType }
from './CombinedChart';
import { PeriodOptions, periodOptions } from '../common';
import { reservedSlugMode as reservedAutoCodeSlugMode } from '../../../../shorten/slug/AutoCode';
import Loader from '../../../../Loader';
import { useEffectExceptOnMount } from '../../../../../hooks';

const Statistics = ({ pather }) => {

    const theme = useTheme();

    const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));

    const [loading, setLoading] = useState(false);

    const [separateChartTypes, setSeparateChartTypes] = useState([]);
    const [chartType, setChartType] = useState(reservedCombinedChartType);
    const [period, setPeriod] = useState(periodOptions[0]);

    const [isEmpty, setIsEmpty] = useState(false);
    const [info, setInfo] = useState({});
    const [chartLabels, setChartLabels] = useState([]);
    const [series, setSeries] = useState([]);

    useEffect(() => {
        chartType === reservedSeparateChartType ? 
            setSeparateChartTypes([reservedAutoCodeSlugMode]) :
            setSeparateChartTypes([]);
    }, [chartType])

    useEffect(() => {
        setIsEmpty(!!!Object.values(info).reduce((accumulator, currentValue) => accumulator + currentValue, 0));
    }, [info]);

    useEffect(() => {
        setInfo({});
        series.forEach(serie => {
            setInfoSafe({
                [serie.label]: serie.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0), 
            })
        });
    }, [series]);

    const setInfoSafe = updStateObj => {
        setInfo(prevState => ({
            ...prevState,
            ...updStateObj,
        }));    
    }

    const fetchStatistics = (isMount = false) => {
        setLoading(true);        
        axios.get(
            pather.back.Settings.statistics, 
            { 
                params: { 
                    period: period.value,
                    chartType,
                    separateChartTypes: JSON.stringify(separateChartTypes),
                    isMount: isMount ? 1 : 0
                }
            }
        )
        .then(response => {
            const data = response.data;
            console.log(data);
            setChartLabels(data?.labels);
            setSeries(data?.series);
            if (isMount && period.value !== data?.taken_period) {
                const newPeriod = periodOptions.find(option => option.value === data?.taken_period);
                setPeriod(newPeriod);
            }
        })
        .catch(error => {
            console.log(error);
            const status = error?.response?.data?.status;
            status && console.log(status);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchStatistics(true);
    }, [])

    useEffectExceptOnMount(() => {
        fetchStatistics();
    }, [chartType, separateChartTypes, period]);

    const setSeparateChartTypesSafe = (checked, value) => {
        if(checked && !separateChartTypes.includes(value)){
            setSeparateChartTypes([
                ...separateChartTypes,
                value,
            ]);
        } else {
            setSeparateChartTypes([
                ...separateChartTypes.filter(item =>item !== value)
            ]);
        }
    }

    const charTypes = (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}> 
            <Box>
                <Typography variant="h6" component="div">
                    Chart Type
                </Typography>
            </Box>
            <SeparateChart
                currentValue={chartType}
                setValue={setChartType}
                separateChartTypes={separateChartTypes}
                setSeparateChartTypesSafe={setSeparateChartTypesSafe}
            />
            <CombinedChart
                currentValue={chartType}
                setValue={setChartType}
            />
        </Box>
    );

    return (
        <>
            {
                loading ? 
                <Loader/> :
                <Box>
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Box sx={{display: 'flex', gap: 5}}>
                            {
                                Object.keys(info).map((label, index) => {
                                    return (
                                        <Typography key={index} variant="h6" component="div">
                                            {label}: {info[label]}
                                        </Typography>
                                    );
                                })
                            }
                        </Box>
                    </Box>
                    <Box sx={{
                        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                        <Box>
                            <Box>
                                {   
                                    series.length > 0 && chartLabels.length > 0 && !isEmpty ?
                                    <BarChart
                                        width={matchesDownMd ? (matchesUpSm ? 500 : 400) : 700}
                                        height={matchesDownMd ? (matchesUpSm ? 350 : 300) : 500}
                                        series={series}
                                        xAxis={[{ data: chartLabels, scaleType: 'band' }]}
                                    /> :
                                    <Box sx={{height: '50vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Typography variant="h6" component="div">
                                            No data
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                            <PeriodOptions 
                                period={period} 
                                setPeriod={setPeriod}
                                size={matchesDownMd ? 'small' : 'medium'}
                            />
                        </Box>
                        {
                            !matchesDownMd && charTypes
                        }
                    </Box>
                </Box>
            }
        </>
    );
}

export {
    periodOptions,
}

export default withPather()(Statistics);