import { RadioExtended } from "../../general";

const reservedSlugMode = 'smartcode';

const SmartCode = ({ slugMode, setSlugMode }) => {
    return (
        <RadioExtended
            currentValue={slugMode}
            value={reservedSlugMode}
            setValue={setSlugMode}
            title='SmartCode'
            desciption='Code that will be generated from the source URL itself using a keyword extraction mechanism.'
            radioName='radio-buttons-code-type' 
        />
    );
}

export {
    reservedSlugMode
}

export default SmartCode;