import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box';
import { withPather } from 'react-pather';
import Loader from '../Loader';
import axios from 'axios';
import { Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import PasscodeCheck from './PasscodeCheck';
import RedirectFailure from './RedirectFailure';

const Redirect = ({ pather }) => {

    const { code } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [source, setSource] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLinkFail, setIsLinkFail] = useState(false);

    // Passcode
    const [isPasscode, setIsPasscode] = useState('');
    const [passcode, setPasscode] = useState('');
    //

    useEffect(() => {
        setIsLoading(true);
        axios.get(
            pather.reverse(pather.back.Conversion.redirectData, { code })
        ).then(response => {
            console.log(response);
            if(response.data.isDead) setIsLoading(true);
            else if (response.data.is_active !== null && response.data.is_active !== undefined && !response.data.is_active) {
                setIsLinkFail(true);
                setIsLoading(false);
                if (response.data.hasOwnProperty('revealable') && response.data.revealable){
                    setSource(response.data.source);
                }
            }
            else if (response.data.isPasscode) {
                setIsPasscode(response.data.isPasscode);
                setIsLoading(false);
                setSource(response.data.source);
            }
            else {
                if (response?.data?.source) window.location.replace(response.data.source);
                else {
                    setIsLoading(false);
                    setError('Current code does not exist!');
                }
            }
        })
        .catch(error => {
            console.log(error);
            setError('Something went wrong!');
            setIsLoading(false);
        });
    }, []);

    const proceed = () => {
        setIsLoading(true);
        axios.post(
            pather.reverse(pather.back.Conversion.checkRedirectPasscode, { code }), 
            { passcode }
        ).then(response => {
            const allowRedirect = response.data.allow_redirect; 
            if (allowRedirect) window.location.replace(source);
            else {
                enqueueSnackbar(response.data.status, { variant: 'error' });
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log(error);
            enqueueSnackbar('Something went wrong!', { variant: 'error' });
            setIsLoading(false);
        });
    }

    return (
        <Container sx={{height: '100%'}}>
            { isLoading && <Loader /> }
            { 
                error &&  
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', minHeight: '320px'}}>
                    <h1>{error} &#128546;</h1>
                </Box>
            }
            {
                !error && !isLoading && isLinkFail &&
                <RedirectFailure source={source} />
            }
            {
                isPasscode && !isLoading &&
                <PasscodeCheck
                    passcode={passcode} 
                    setPasscode={setPasscode}
                    proceed={proceed}
                />
            }
        </Container>
    );
}

export default withPather()(Redirect);