import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const Revealable = ({ advanced, setAdvancedSafe, days }) => {
    return (
        <Box>
            <FormControlLabel
                checked={advanced.revealable}
                control={<Checkbox/>}
                label="Show source url when shortend link expire"
                labelPlacement="end"
                disabled={!!!advanced.maxRedirects && days === ''}
                onChange={e => setAdvancedSafe({revealable: e.target.checked})}
            />
        </Box>
    );
}

export default Revealable;