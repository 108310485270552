
const Auth = {
    path: '/auth',
    isSection:  true,
    sub: {
        login: '/login',  
        logout: '/logout',  
        register: '/register',  
        currentUser: '/current',
    },
}

export default Auth;