import { 
    useTheme,
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
} from '@mui/material';

const SwitchableBlock = ({ switcher, handleSwitch, label, field, description }) => {
    const theme = useTheme();
    return (
        <>
            {!switcher ?
            <Box>
                <FormControlLabel
                    checked={switcher}
                    control={<Checkbox/>}
                    label={label}
                    labelPlacement="end"
                    onChange={handleSwitch}
                />
            </Box> :
            <Box className="square" sx={{
                ml: -3, 
                width: '90%', 
                height: '100%', 
                p: 3, 
                boxShadow: theme.palette.squareBoxShadow
            }}>
                <Box>
                    <FormControlLabel
                        checked={switcher}
                        control={<Checkbox/>}
                        label={label}
                        labelPlacement="end"
                        onChange={handleSwitch}
                    />
                    {switcher &&
                    <Box sx={{display: 'flex', flexDirection: 'column', rowGap: '10px'}}>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <Box sx={{width: '100%'}}>
                                {field}
                            </Box>
                        </Box>
                        <Box>
                            <Typography component="div" sx={{fontSize: '12px'}}>
                                {description}
                            </Typography>
                        </Box>
                    </Box>
                    }  
                </Box> 
            </Box>
            }
        </>
    );
}

export default SwitchableBlock;