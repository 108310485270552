import { 
    useState,
    useEffect
} from 'react';
import { 
    useTheme, 
    useMediaQuery, 
    Box, 
    Pagination
} from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { withPather } from 'react-pather';
import ConversionListSettings from './ConversionListSettings';
import ConversionRow from './ConversionRow';
import Loader from '../../../Loader';
import { useEffectExceptOnMount } from '../../../../hooks';

const ConversionListViewer = ({ 
    pather, 
    settings, 
    setMethods, 
    settingsTopBar,
    conversionRowOptions = {}, 
    withViewSwitcher = true
}) => {

    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const matches = useMediaQuery(theme.breakpoints.only('xs'));

    const [loading, setLoading] = useState(false);
    const [queryLoading, setQueryLoading] = useState(false);

    const [conversions, setConversions] = useState([]);
    const [numberOfResults, setNumberOfResults] = useState(null);
    const [totalPages, setTotalPages] = useState(1);

    const { 
        query, 
        perPage,
        offset,
        freshness,
        mode,
        dateSort,
        startDate, 
        endDate,
    } = settings;

    const { 
        setOffset, 
        setPerPage 
    } = setMethods;

    const getConversions = (queryChanged = false, success = null) => {

        queryChanged ?
        setQueryLoading(true) :
        setLoading(true);

        axios.get(
            pather.back.conversions, 
            { 
                params: { 
                    query,
                    perPage,
                    offset,
                    freshness,
                    mode,
                    dateSort,
                    startDate: startDate ? startDate.format("DD-MM-YYYY") : startDate,
                    endDate: endDate ? endDate.format("DD-MM-YYYY") : endDate,
                } 
            }
        ).then(response => {
            const data = response.data;
            setConversions(data.conversions);
            setTotalPages(data.total_pages);
            setNumberOfResults(data.number_of_results);
            success && success();
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            queryChanged ?
            setQueryLoading(false) :
            setLoading(false);
        });
    }

    const handleDelete = code => {
        setLoading(true);
        axios.delete(
            pather.reverse(pather.back.Conversion.delete, { code })
        ).then(response => {
            const data = response.data;
            const status = data?.status;
            getConversions();
            status && enqueueSnackbar(status, { variant: 'success' });
        })
        .catch(error => {
            console.log(error);
            const status = error?.response?.data?.status;
            status && enqueueSnackbar(status, { variant: 'error' });
        })
        .finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        getConversions();
    }, []);

    useEffectExceptOnMount(() => {
        getConversions();
    }, [offset]);

    useEffectExceptOnMount(() => {
        getConversions(false, () => {
            setOffset(0);
        });
    }, [perPage, freshness, mode, dateSort, startDate, endDate]);

    useEffectExceptOnMount(() => {
        getConversions(true, () => {
            setOffset(0);
        });
    }, [query]);

    const handleOffsetChange = (event, value) => setOffset(value - 1);
    const handlePerPageChange = event => setPerPage(event.target.value);

    return (
        <>
            {
                loading ? 
                <Loader/> :
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Box>
                        { settingsTopBar }
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: {sm: 2}}}>
                        <ConversionListSettings 
                            queryLoading={queryLoading}
                            numberOfResults={numberOfResults} 
                            handlePerPageChange={handlePerPageChange}
                            settings={settings}
                            setMethods={setMethods}
                            withViewSwitcher={withViewSwitcher}
                        />
                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            {
                                conversions.length > 0 ? 
                                conversions.map(conv => {
                                    return (
                                        <ConversionRow 
                                            key={conv.id}
                                            conversion={conv}
                                            settings={settings}
                                            handleDelete={handleDelete}
                                            {...conversionRowOptions}
                                        />
                                    );
                                }) :
                                <Box sx={{display: 'flex', width: '100%', justifyContent: 'center', mt: 10, mb: 10}}>
                                    <h3>No results</h3>
                                </Box>
                            }
                        </Box>
                        {   
                            totalPages > 1 && 
                            <Box sx={{width: '100%', mt: 3, display: 'flex', justifyContent: 'center'}}>
                                <Pagination
                                    count={totalPages} 
                                    page={offset + 1} 
                                    variant="outlined" 
                                    color="primary" 
                                    size={matches ? "medium" : "large"}
                                    onChange={handleOffsetChange}
                                />
                            </Box>
                        }
                    </Box>
                </Box>
            }
        </>
    );
}

export default withPather()(ConversionListViewer);